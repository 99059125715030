import { getEnv } from './lib/env.js';

(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
  j.async = true;
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', getEnv('TAG_MENAGER_ID') || 'GTM-KJLCFGT');


let appConfig = {
  theme: {
    nav: {
      label: 'Pure.',
    },
    showHeaderOnAuthRoutes: true,
    showInputIconsOnLogin: false,
    routesWithoutStaticHeader: ['/item-details'],
  },
  services: {
    google_maps: {
      google_api_key: getEnv('GOOGLE_API_KEY'),
    },
    // stripe_key: 'pk_test_SfLlfP41LhHVIG2vrA95ZrFt' //Kole
  },
  api: {
    baseURL: getEnv('REACT_APP_API_URL'),
    // baseURL: 'https://hybrid-master.herokuapp.com',
    // baseURL: 'http://192.168.8.103:8000', //preda
    // baseURL: 'http://192.168.0.242:8000', //preda
    wordPressBaseURL: 'https://hybridapp.co.uk',
  },
  general: {
    clientName: 'Healthy Retail Ltd T/A Pure',
    appExitRoutes: ['/', '/home', '/dashboard'],
    authRoutes: ['/login', '/register', '/reset-password'],
    isReduxDevToolsOn: true,
    isWebPlatform: false,
    basketTime: -5,
    charterDeliveryAheadDays: 30,
  },
  appType: {
    hasOrdering: true,
    hasLoyalty: true,
    hasCampaignManager: true,
    hasEmailValidationEnabled: true,
  },
  delivery: [
    {
      id: 'delivery',
      label: 'Order for Delivery',
      route: '/delivery',
    },
    {
      id: 'collection',
      label: 'Click & Collect in Store',
      route: '/click-and-collect',
    },
    {
      id: 'reorder',
      label: 'Order Again',
      
    },
    {
      id: 'pick-up-point',
      label: 'Pure Drop Point',
      route: '/pick-up-point',
    },
  ],
  configS3: {
    imageNamePrefix: 'pure_profile_image_',
    bucketName: 'hybrid-apps-profile-images',
    region: 'eu-west-1',
    accessKeyId: getEnv('S3_ACCESS_KEY_ID'),
    secretAccessKey: getEnv('S3_SECRET_ACCESS_KEY'),
  },
  firebaseConfig: {
    apiKey: getEnv('FIREBASE_API_KEY'),
    authDomain: getEnv('FIREBASE_AUTH_DOMAIN'),
    databaseURL: getEnv('FIREBASE_DATABASE_URL'),
    projectId: getEnv('FIREBASE_PROJECT_ID'),
    storageBucket: getEnv('FIREBASE_STORAGE_BUCKET'),
    messagingSenderId: getEnv('FIREBASE_MESSAGING_SENDER_ID'),
    appId: getEnv('FIREBASE_APP_ID'),
    measurementId: getEnv('FIREBASE_MEASUREMENT_ID'),
  },
  payment: 'stripe',
  orderCompletedStatus: 'ORDER_COMPLETED',
  menuDisplayType: 2,
};

export const updateConfig = (newConfig) => (appConfig = newConfig);

export const getConfig = () => appConfig;

export default appConfig;
