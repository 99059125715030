import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { IonButton, IonIcon } from '@ionic/react';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { checkmarkCircle, closeCircleSharp } from 'ionicons/icons';
import './index.css';
import { Title, NormalText } from '../../components/common';
import { forwardTo, isWebConfig } from '../../lib/utils';
import { Capacitor } from '@capacitor/core';
import Basket from '../../lib/basket';
import { loading } from '../../store/actions';

const isWeb = () => Capacitor.getPlatform() === 'web';

const MembershipCompleted = ({ __, isMenuFlow }) => {
  const handleMembership = () => {
    forwardTo('/my-membership');
  };
  const selectedSubscription = useSelector((state) => state.orders.selectedSubscription);
  const subscriptions = useSelector((state) => state.subscription.subscriptions);
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedSubscription) {
      const subscriptionIdx = selectedSubscription?.idx;
      Basket.setSubscription(subscriptions[subscriptionIdx]);
      dispatch(loading(false));
    }
  }, []);

  const handleCheckout = () => {
    const path = isWebConfig() ? '/order' : '/dashboard';
    forwardTo(path);
  };

  return !window.location.href.includes('canceled=true') ? (
    <Loading>
      <Layout
        hideSecondToolbar
        color="transparent"
        noPadding
        headerTitle={__('Membership Completed')}
      >
        <div className="absolute-content flex-row-wrapper">
          <div className="scrollable-y ion-text-center">
            <IonIcon
              expand="block"
              color="success"
              icon={checkmarkCircle}
              className="success-icon"
            />
            <Title>{__('Success')}</Title>
            <NormalText tag="div" className="description">
              {__(
                'Thanks for joining Pure +more! You’re all set to start enjoying your 20% discount, as well as all your other membership perks',
              )}
            </NormalText>
          </div>
          <div className="flex-min">
            {isMenuFlow ? (
              <IonButton
                expand="block"
                className={'checkout-btn '}
                onClick={() => handleMembership}
              >
                {__('View My Membership')}
              </IonButton>
            ) : (
              <IonButton
                expand="block"
                className={'checkout-btn '}
                onClick={() => handleCheckout()}
              >
                {__('Back to Home')}
              </IonButton>
            )}
          </div>
        </div>
      </Layout>
    </Loading>
  ) : (
    <Loading transparent>
      <Layout hideSecondToolbar color="transparent" headerTitle={__('Membership Canceled')}>
        <div className="absolute-content flex-row-wrapper">
          <div className="scrollable-y ion-text-center">
            <IonIcon
              expand="block"
              color="danger"
              icon={closeCircleSharp}
              className="success-icon"
            />
            <Title>{__('Payment Canceled')}</Title>
          </div>
          <div className="flex-min">
            <IonButton
              color="primary"
              expand="block"
              className={'checkout-btn '}
              onClick={() => handleCheckout()}
            >
              {__('Back to Home')}
            </IonButton>
          </div>
        </div>
      </Layout>
    </Loading>
  );
};

const mapStateToProps = (store) => ({
  isMenuFlow: store.subscription.flow === 'menu_flow',
  selectedSubscription: store.orders.selectedSubscription,
  subscriptions: store.subscription.subscriptions,
});

export default connect(mapStateToProps)(withTranslation(MembershipCompleted));
