import { isWebConfig } from './lib/utils';
import Dashboard from './screens/dashboard';
import Loyalty from './screens/loyalty';
import Vouchers from './screens/vouchers';
import Account from './screens/account';
import Feedback from './screens/feedback';
import ReferAFriend from './screens/refer-a-friend';
import History from './screens/history';
import HistoryDetails from './screens/historyDetails';
import Locations from './screens/locations';
import Social from './screens/social';
import Login from './screens/login';
import Register from './screens/register';
import ResetPassword from './screens/resetPassword';
import Terms from './screens/terms';
import Privacy from './screens/privacy';
import Faq from './screens/faq';
import ItemDetails from './screens/itemDetails';
import DeliveryOptions from './screens/delivery-options';
import Delivery from './screens/delivery';
import DeliveryAddressCheck from './screens/delivery-address-check';
import DeliveryAddressAdd from './screens/delivery-address-add';
import DeliveryTime from './screens/delivery-time';
import ScheduledDeliveryTime from './screens/scheduled-delivery-time';

import PickUpPoint from './screens/pick-up-point';
import PickUpPointCheck from './screens/pick-up-point-check';
import ClickAndCollect from './screens/clickAndCollect';
import Cards from './screens/cards';
import CardAdd from './screens/cardAdd';
import OrderSummary from './screens/orderSummary';
import ApplyPoints from './screens/applyPoints';
import Checkout from './screens/checkout';
import OrderCompleted from './screens/orderCompleted';
import ContactDetails from './screens/contactDetails';
import ApplyVouchers from './screens/applyVouchers';
// import AllergensInfo from './screens/allergensInfo'
import { getConfig } from './appConfig';
import { getSingleDeliveryOption } from './lib/utils';
import ChooseSubscription from './screens/chooseSubscription';
import ChooseSubscriptionMenu from './screens/chooseSubscriptionMenu';
import TermsAndConditions from './screens/terms-subscription-package';
import MyMembership from './screens/myMembership';
import MembershipCompleted from './screens/membership-completed';
import CheckoutMembership from './screens/checkoutMembership';
import CardAddMembersip from './screens/cardAddMembersip';
import home from './assets/images/home-icon.svg';
import loyalty from './assets/images/icons/wallet.svg';
import myAccount from './assets/images/icons/account.svg';
import feedback from './assets/images/icons/feedback.svg';
import referAFriend from './assets/images/icons/refer.svg';
import history from './assets/images/icons/history.svg';
import restaurants from './assets/images/icons/locations.svg';
import social from './assets/images/social-icon.svg';
import login from './assets/images/login-icon.svg';
import logout from './assets/images/logout-icon.svg';
import settings from './assets/images/settings-icon.svg';
import voucuher from './assets/images/icons/vouchers.svg';
import startNewOrder from './assets/images/start-new-order.svg';
import menu from './assets/images/menu.svg';
import terms from './assets/images/icons/terms.svg';
import privacy from './assets/images/icons/privacy.svg';
import qm from './assets/images/icons/faq.svg';
import subscriptions from './assets/images/icons/membership.svg';
import chat from './assets/images/icons/chat.svg';

const Order = require('./screens/order' + (isWebConfig() ? 'Web' : '')).default;
const { hasOrdering, hasCampaignManager } = getConfig().appType;
const singleDeliveryOption = getSingleDeliveryOption();

const navConfig = {
  routes: [
    isWebConfig()
      ? {
          label: 'Menu',
          path: '/order',
          component: Order,
          icon: menu,
          exact: true,
          default: isWebConfig(),
        }
      : {
          label: 'Home',
          path: '/dashboard',
          component: Dashboard,
          icon: home,
          exact: true,
          default: true,
        },
    ...(hasOrdering
      ? [
          {
            label: singleDeliveryOption ? singleDeliveryOption.label : '',
            path: '/click-and-collect',
            component: ClickAndCollect,
            icon: settings,
            protected: false,
            notInDrawer: !singleDeliveryOption,
          },
        ]
      : []),
    ...(hasOrdering
      ? [
          {
            label: 'Start New Order',
            path: '/delivery-options',
            component: DeliveryOptions,
            icon: startNewOrder,
            protected: false,
            notInDrawer: true,
          },
        ]
      : []),
    {
      label: 'Loyalty',
      path: '/loyalty',
      component: Loyalty,
      icon: loyalty,
      protected: true,
      state: { tab: 'points' },
      group: 1,
    },

    ...(hasCampaignManager
      ? [
          {
            label: 'Vouchers',
            path: '/vouchers',
            component: Vouchers,
            icon: voucuher,
            protected: true,
            group: 1,
          },
        ]
      : []),
    {
      label: 'Refer A Friend',
      path: '/refer-a-friend',
      component: ReferAFriend,
      icon: referAFriend,
      protected: false,
      group: 1,
    },
    {
      label: 'Membership',
      path: '/get-membership',
      component: ChooseSubscriptionMenu,
      icon: subscriptions,
      protected: true,
      group: 1,
    },
    {
      label: 'Membership',
      path: '/membership',
      component: ChooseSubscription,
      icon: subscriptions,
      notInDrawer: true,
      protected: true,
    },
    ...(hasOrdering
      ? [
          {
            label: 'Checkout',
            path: '/checkout',
            component: Checkout,
            notInDrawer: true,
            protected: true,
          },
        ]
      : []),
    ...(hasOrdering
      ? [
          {
            label: 'Apply Points',
            path: '/apply-points',
            component: ApplyPoints,
            notInDrawer: true,
            protected: true,
          },
        ]
      : []),
    {
      label: 'My Account',
      path: '/account',
      component: Account,
      icon: myAccount,
      protected: true,
      group: 2,
    },
    {
      label: 'History',
      path: '/history',
      component: History,
      icon: history,
      protected: true,
      group: 2,
    },
    {
      label: 'Leave Feedback',
      path: '/feedback',
      component: Feedback,
      icon: feedback,
      protected: true,
      group: 2,
    },

    {
      label: 'Social Media',
      path: '/social',
      component: Social,
      icon: social,
      protected: false,
      group: 3,
      notInDrawer: true,
    },
    {
      label: 'Contact Details',
      path: '/contact-details',
      component: ContactDetails,
      protected: true,
      notInDrawer: true,
    },
    {
      label: 'Apply Vouchers',
      path: '/apply-vouchers',
      component: ApplyVouchers,
      notInDrawer: true,
      protected: true,
    },
    {
      label: 'TermsAndConditions',
      path: '/membership-terms',
      component: TermsAndConditions,
      notInDrawer: true,
      protected: true,
    },
    {
      label: 'MyMembership',
      path: '/my-membership',
      component: MyMembership,
      notInDrawer: true,
      protected: true,
    },
    {
      label: 'Membership Completed',
      path: '/membership-completed',
      component: MembershipCompleted,
      notInDrawer: true,
      protected: false,
    },
    {
      label: 'Checkout Membership',
      path: '/checkout-membership',
      component: CheckoutMembership,
      notInDrawer: true,
      protected: true,
    },
    {
      label: 'Add card Membership',
      path: '/add-card-membership',
      component: CardAddMembersip,
      notInDrawer: true,
      protected: true,
    },
    {
      label: 'Live Chat',
      path: 'https://tawk.to/chat/62289779a34c2456412a3679/1ftn8vckn',
      icon: chat,
      exact: true,
      isLink: true,
      group: 3,
    },
    {
      label: 'Locations',
      path: '/locations',
      component: Locations,
      icon: restaurants,
      protected: false,
      group: 3,
    },
    { label: 'FAQ & Support', path: '/faq', component: Faq, icon: qm, group: 3 },

    { label: 'T&Cs', path: '/terms', component: Terms, icon: terms, group: 3 },
    { label: 'Privacy Policy', path: '/privacy', component: Privacy, icon: privacy, group: 3 },
    {
      label: 'Allergens Info',
      path: 'https://hybridapp.co.uk/en/allergen-page/pure-uk',
      icon: qm,
      exact: true,
      isLink: true,
    },
  ],
  authRoutes: [
    { label: 'Login', path: '/login', component: Login, icon: login, fn: 'login' },
    { label: 'Logout', path: '/logout', icon: logout, fn: 'logout' },
  ],
  additionalRoutes: [
    // { label: '', path: '/allergens-info', component: AllergensInfo, icon: qm }
  ],
  notInMenuRoutes: [
    { path: '/register', component: Register },
    { path: '/reset-password', component: ResetPassword },
    ...(hasOrdering ? [{ path: '/order', component: Order }] : []),
    ...(hasOrdering ? [{ path: '/item-details', component: ItemDetails }] : []),
    ...(hasOrdering ? [{ path: '/cards', component: Cards }] : []),
    ...(hasOrdering ? [{ path: '/card-add', component: CardAdd }] : []),
    ...(hasOrdering ? [{ path: '/history-details', component: HistoryDetails }] : []),
    ...(hasOrdering ? [{ path: '/order-summary', component: OrderSummary }] : []),
    ...(hasOrdering ? [{ path: '/order-completed', component: OrderCompleted }] : []),
    ...(hasOrdering ? [{ path: '/delivery', component: Delivery }] : []),
    ...(hasOrdering ? [{ path: '/delivery-address-check', component: DeliveryAddressCheck }] : []),
    ...(hasOrdering ? [{ path: '/delivery-address-add', component: DeliveryAddressAdd }] : []),
    ...(hasOrdering ? [{ path: '/delivery-time', component: DeliveryTime }] : []),
    ...(hasOrdering
      ? [{ path: '/scheduled-delivery-time', component: ScheduledDeliveryTime }]
      : []),

    ...(hasOrdering ? [{ path: '/pick-up-point', component: PickUpPoint }] : []),
    ...(hasOrdering ? [{ path: '/pick-up-point-check', component: PickUpPointCheck }] : []),
  ],
};

export default navConfig;
