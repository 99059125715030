import React from 'react';
import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import moment from 'moment';
import {
  ellipseOutline,
  checkmarkCircle,
  remove,
  ellipsisHorizontalCircleSharp,
  closeCircle,
} from 'ionicons/icons';
import { NormalText, SmallText } from '../common';

const ITEM_STATES = {
  start: { icon: remove, color: 'colorNormal' },
  pending: { icon: ellipseOutline, color: 'colorNormal' },
  done: { icon: checkmarkCircle, color: 'colorDone' },
  in_progress: { icon: ellipsisHorizontalCircleSharp, color: 'colorNormal' },
  error: { icon: closeCircle, color: 'colorError' },
};

export const OrderLine = ({ status, datetime, label }) => {
  const { icon, color } = ITEM_STATES[status] ? ITEM_STATES[status] : ITEM_STATES['pending'];

  return (
    <IonItem>
      <div className="status-container">
        <div className="vertical-line"></div>
        <IonIcon icon={icon} className={'icon-div ' + color} />
      </div>
      <div className="status-time-container">
        <SmallText color="gray" className="block">
          {datetime && (
            <>{`${moment(datetime).format('DD/MM/YYYY')} ${moment(datetime).format('h:mm a')}`}</>
          )}
        </SmallText>
        <NormalText className="">{label}</NormalText>
      </div>
    </IonItem>
  );
};
