import React from 'react';
import './index.css';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import { IonList } from '@ionic/react';
import { getConfig } from '../../appConfig';
import { OrderLine } from './OrderLine';

const getLastEvent = (order, orderType = '') => {
  if (!orderType) return [];
  const config = getConfig();
  const eventOrderType = orderType === 'pick-up-point' ? 'delivery' : orderType;
  const eventsTemplate =
    config && config.events_template && config.events_template[eventOrderType.toLocaleLowerCase()];
  const showAll = config.flags?.showAllOrderStatuses;
  const events = mergeEvents(eventsTemplate, order.events, showAll);
  const lastEventIndex = events.findIndex(
    (event) => event.status === 'pending' || event.status === 'in_progress',
  );
  return lastEventIndex > -1 ? events[lastEventIndex - 1] : events.at(-1);
};

const updateOrder = (order, latestOrderEvent) => {
  if (latestOrderEvent && order?.id === latestOrderEvent.id) {
    order.events = latestOrderEvent.events || [];
    order.status = latestOrderEvent.status;
  }
};

const lastEventForPill = (order, orderType, __) => {
  const lastEvent = getLastEvent(order, orderType);
  const pillItems = [];

  if (lastEvent) {
    const label = lastEvent.label;
    if (label) {
      pillItems.push(label);
    }
  }
  return pillItems;
};

const mergeEvents = (eventsTemplate, orderEvents, showAll = true) => {
  if (!eventsTemplate || orderEvents.length === 0) {
    return [];
  }
  let events = [];
  let setNextEventInProgress = true;
  if (orderEvents[orderEvents.length - 1].order_status == 'PAID') {
    orderEvents.push({
      type: 'PURE_APP',
      timestamp: orderEvents[orderEvents.length - 1].timestamp,
      order_status: 'COMPLETED',
    });
  }
  for (let eventTemplate of eventsTemplate) {
    let line;
    for (let i = 0; i < orderEvents.length; i++) {
      const orderStatus = orderEvents[i].order_status;
      const deliveryStatus = orderEvents[i].delivery_status
        ? orderEvents[i].delivery_status
        : 'invaldStatus';
      const haveAlternativeStatus = eventTemplate?.alternative_status
        ? eventTemplate?.alternative_status
        : false;
      const alternativeStatusIsDone = haveAlternativeStatus
        ? orderEvents.find((event) => event.order_status === eventTemplate.alternative_status)
        : false;
      const orderTimestamp = orderEvents[i].timestamp;

      if (orderStatus) {
        if (
          alternativeStatusIsDone ||
          eventTemplate?.status === orderStatus ||
          eventTemplate?.status === deliveryStatus
        ) {
          line = {
            status: 'done',
            datetime: orderTimestamp,
            label: eventTemplate?.text || orderStatus,
          };
        } else if (
          eventTemplate?.error_status === orderStatus ||
          eventTemplate?.error_status === deliveryStatus
        ) {
          setNextEventInProgress = false;
          line = {
            status: 'error',
            datetime: orderTimestamp,
            label: eventTemplate?.error_text || orderStatus,
          };
        } else if (
          eventTemplate?.pending_status === orderStatus ||
          eventTemplate?.pending_status === deliveryStatus
        ) {
          setNextEventInProgress = false;
          line = {
            status: 'in_progress',
            datetime: orderTimestamp,
            label: eventTemplate?.pending_value || orderStatus,
          };
        }
      }
    }

    if (setNextEventInProgress && !line && !eventTemplate.hide_from_flow) {
      setNextEventInProgress = false;
      line = {
        status: 'in_progress',
        label: eventTemplate?.pending_value || eventTemplate?.text,
      };
    } else if (showAll && !line && !eventTemplate.hide_from_flow) {
      line = {
        status: 'pending',
        label: eventTemplate?.pending_value || eventTemplate?.text,
      };
    }
    if (line) {
      events.push(line);
      const stopAllEvents = eventTemplate.all_next_events_stop || false;
      if (stopAllEvents) {
        events = events.filter(
          (event) => event.status !== 'in_progress' && event.status !== 'pending',
        );
        break;
      }
    }
  }
  return events;
};

const OrderStatus = ({ order, orderType = '' }) => {
  const eventOrderType = orderType === 'pick-up-point' ? 'delivery' : orderType;
  const config = getConfig();
  const eventsTemplate =
    config && config.events_template && config.events_template[eventOrderType.toLocaleLowerCase()];
  const showAll = config.flags?.showAllOrderStatuses;
  const events = mergeEvents(eventsTemplate, order.events, showAll);
  return (
    <IonList className="order-status-track" lines="none">
      {events.map((event, index) => (
        <OrderLine status={event?.status} datetime={event?.datetime} label={event?.label} />
      ))}
    </IonList>
  );
};

const mapStateToProps = (store) => ({
  profile: store.profile.profile,
  orderHistory: store.orders.orderHistory,
});

export { lastEventForPill, updateOrder };
export default connect(mapStateToProps)(withRouter(withTranslation(OrderStatus)));
