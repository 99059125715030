export const createDiscount = (subscription) => {
  const max_amount = subscription.max_discount_per_transaction / 100;
  const discount = subscription.discount_rate;
  const discountName = replaceLastWord(subscription.name, 'Discount');
  return {
    title: `${discount}% ` + discountName,

    calculate_discount: (subtotal) => {
      let result = (subtotal * discount) / 100;
      return result <= max_amount ? result : max_amount;
    },
  };
};

export const createDiscountPackage = (subscription) => {
  const price = subscription.number_of_free_periods > 0 ? 0 : subscription.monthly_cost_inc_vat;
  return {
    title: subscription.name,
    price: price / 100,
  };
};

const replaceLastWord = (string, newWord) => {
  if (!string) {
    return newWord;
  }
  const index = string.lastIndexOf(' ');
  if (index === -1) {
    return string + ' ' + newWord;
  }
  return string.substring(0, index + 1) + newWord;
};
