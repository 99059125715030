import React from 'react';
import {
  IonList,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonNote,
  IonButton,
} from '@ionic/react';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { Title, SmallText, Spacer, Sectiontitle } from '../../components/common';
import { connect } from 'react-redux';
import { Capacitor } from '@capacitor/core';
import Basket from '../../lib/basket';
import DrawPayButton from '../../components/drawPayButton';
import CheckoutPay from './CheckoutPay';
import { forwardTo, goBack, isWebConfig, sprintf } from '../../lib/utils';
import './index.css';
import { showToast } from '../../store/actions';
import { getConfig } from '../../appConfig';

const { getMobile, setMobile } = Basket;

const isWeb = () => Capacitor.getPlatform() === 'web';

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    this.state = {
      selectedCard: null,
      disabledBtn: false,
    };
  }

  componentDidMount() {
    const { profile } = this.props;
    const { google } = window;
    if (profile.cardToken) {
      this.changeSelectedPaymentCard(profile.cardToken);
    } else {
      forwardTo('/checkout');
    }
    if (google) {
      window.googlePayClient = new google.payments.api.PaymentsClient({
        environment: 'PRODUCTION',
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile.cardToken !== this.props.profile.cardToken) {
      this.changeSelectedPaymentCard(this.props.profile.cardToken);
    }
  }

  changeSelectedPaymentCard = (cardId) =>
    this.setState({ selectedCard: cardId }, () => {
      Basket.changeSelectedCard(cardId);
    });

  componentWillUnmount() {
    this.setState({ disabledBtn: false });
  }

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.skipContactDetails
    ) {
      forwardTo('/order-summary', { skipBackToThePreviousPage: true });
    } else if (this.props.location.pathname === '/checkout') {
      if (this.props.location && this.props.location.state && this.props.location.state.fromHistory)
        forwardTo('/history');
      else forwardTo('/order');
    } else {
      goBack();
    }
  };

  render() {
    const { __, cards, clientSecret, dispatch, location, profile } = this.props;
    const basketInstance = this.props.basketInstance || Basket;
    const totalAmount = basketInstance.formatPrice(basketInstance.getTotal());

    return (
      <Loading>
        <Layout hideSecondToolbar headerTitle={__('Checkout')} backHandler={this.backHandler}>
          <div className="flex-row-wrapper absolute-content">
            <Title>{__('Checkout')}</Title>
            {isWeb() ? (
              <SmallText>{__('Use saved payment card')}</SmallText>
            ) : null
            }
            <Spacer size={1} />
            {isWeb() ? (
              <>
                <div className="scrollable-y">
                  <IonList className="box-wrapper box-list" lines="none">
                    <IonRadioGroup
                      onIonChange={(e) => this.changeSelectedPaymentCard(e.detail.value)}
                      value={this.state.selectedCard}
                    >
                      {cards
                        .reduce((acc, item) => {
                          if (!acc.find((el) => el.fingerprint === item.fingerprint)) {
                            acc.push(item);
                          }
                          return acc;
                        }, [])
                        .map((card) => {
                          const { id, last4, brand, exp_month, exp_year, name } = card;

                          return (
                            <IonItem key={id}>
                              <div tabIndex="-1"></div>
                              <IonRadio
                                color={isWebConfig() ? 'secondary' : 'white'}
                                slot="start"
                                value={id}
                                onIonSelect={() => {
                                  this.setState({ selectedCard: id }, () => {
                                    Basket.changeSelectedCard(id);
                                  });
                                }}
                              />
                              <IonLabel className="ion-text-wrap">
                                <Sectiontitle className="single-item">{name}</Sectiontitle>
                                <Sectiontitle className="no-margin">
                                  **** **** **** {last4}
                                </Sectiontitle>
                                <IonNote>
                                  {__(brand)} - {exp_month}/{exp_year}
                                </IonNote>
                              </IonLabel>
                            </IonItem>
                          );
                        })}
                    </IonRadioGroup>
                  </IonList>
                  <IonButton
                    fill="clear"
                    color="dark"
                    className="link underlined"
                    onClick={() => forwardTo('/card-add', { addCardFromCheckout: true })}
                  >
                    {__((cards.length > 0 ? 'Or add another' : 'Add ') + ' payment card')}
                  </IonButton>
                </div>
                <div className="flex-min">
                  <CheckoutPay
                    clientSecret={clientSecret}
                    dispatch={dispatch}
                    __={__}
                    location={location}
                    profile={profile}
                  />

                  <IonButton
                    disabled={cards.length === 0 || this.state.disabledBtn}
                    onClick={() => {
                      if (Basket.getTotal() === 0 || Basket.getTotal() > 0.49) {
                        this.setState({ disabledBtn: true });
                        Basket.createOrder();
                      } else {
                        dispatch(
                          showToast(
                            __(
                              'Please add more items to your basket. Amount to pay must be at least £0.50',
                            ),
                            'warning',
                          ),
                        );
                      }
                    }}
                    expand="block"
                  >
                    {sprintf(__('Pay %s'), totalAmount)}
                  </IonButton>
                  {(isWeb() || getConfig()?.prebuildPayment?.showOnNative) && getConfig()?.prebuildPayment?.isVisible ? (
                    <IonButton
                      style={{ marginBottom: '10px' }}
                      className="uppercase okx-font-secondary"
                      onClick={() => {
                        Basket.createStripeOrder(getConfig().prebuildPayment?.label);
                      }}
                      expand="block"
                    >
                      {__(getConfig().prebuildPayment?.label)}
                    </IonButton>
                  ) : null}
                  {profile?.can_pay_on_account ? (
                    <IonButton
                      disabled={this.state.disabledBtn}
                      className={'custom-ion-button-margin'}
                      fill="clear"
                      color="black"
                      onClick={() => {
                        this.setState({ disabledBtn: true });
                        Basket.createOrder('payOnAccount');
                      }}
                      expand="block"
                    >
                      {sprintf(__('Pay %s on Account'), totalAmount)}
                    </IonButton>
                  ) : null}
                </div>
              </>
            ) : null
            }
            {!isWeb() ? (
              <>
              <div className="scrollable-y">
                
              </div>
              <div className="flex-min">
                  {!isWeb() && getConfig().nativePayment?.isVisible ? (
										<IonButton
											style={{ marginBottom: '10px' }}
											className="uppercase okx-font-secondary"
											onClick={() => {
												Basket.createOrder('NativePayment');
											}}
											expand="block"
										>
											{__(getConfig().nativePayment?.label)}
										</IonButton>
									) : null}
                {(isWeb() || getConfig()?.prebuildPayment?.showOnNative) && getConfig()?.prebuildPayment?.isVisible ? (
                  <IonButton
                    style={{ marginBottom: '10px' }}
                    className="uppercase okx-font-secondary"
                    onClick={() => {
                      Basket.createStripeOrder(getConfig().prebuildPayment?.label);
                    }}
                    expand="block"
                  >
                    {__(getConfig().prebuildPayment?.label)}
                  </IonButton>
                ) : null}
                {profile?.can_pay_on_account ? (
                  <IonButton
                    disabled={this.state.disabledBtn}
                    className={'custom-ion-button-margin'}
                    fill="clear"
                    color="black"
                    onClick={() => {
                      this.setState({ disabledBtn: true });
                      Basket.createOrder('payOnAccount');
                    }}
                    expand="block"
                  >
                    {sprintf(__('Pay %s on Account'), totalAmount)}
                  </IonButton>
                ) : null}
              </div>
            </>
            ) : null
            }
          </div>
        </Layout>
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    cards: store.orders.cards || [],
    profile: store.profile.profile || {},
    clientSecret: store.orders.clientSecret,
  };
};

export default connect(mapStateToProps)(withTranslation(Checkout));
