import React from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonRadioGroup,
  IonRadio,
  IonButtons,
  IonAlert,
  IonIcon,
} from '@ionic/react';
import Layout from '../../components/layout';
import { Title, NormalText, SmallText, Spacer } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import {
  forwardTo,
  checkForDeliveryOption,
  isEmptyObject,
  isDefined,
  isWebConfig,
} from '../../lib/utils';
import {
  setDeliveryAddress,
  postCodeCheck,
  setPostCodeData,
  getNearestLocation,
  setCommonModal,
  removeDeliveryAddress,
  setOrdersProp,
} from '../../store/actions';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';

import './index.css';
import { getConfig } from '../../appConfig';
import { trash } from 'ionicons/icons';

class Delivery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAddress: props.location.state?.selectedRestaurant
        ? props.location.state.selectedRestaurant.delivery_address
        : null,
      deliveryZoneOption: '',
      deliveryZone: [],
      restaurant: {},
      removeDeliveryAddress: null,
    };
  }
  componentDidMount() {
    Basket.setOrderType('delivery');
    const { deliveryOption } = this.props;
    let delivery_id = deliveryOption && deliveryOption.id ? deliveryOption.id : '';
    let dataForDeliveryOption = null;
    if (!delivery_id) {
      const order_type = Basket.getOrderType();
      const deliveryConfig = getConfig().delivery;
      Object.keys(deliveryConfig).map((key) => {
        if (deliveryConfig[key].id === order_type.toLowerCase()) {
          dataForDeliveryOption = deliveryConfig[key];
        }
      });
      delivery_id = dataForDeliveryOption.id;
    }
    Basket.setOrderType(delivery_id);
    if (!this.props.location.state) checkForDeliveryOption(Basket.getDeliveryOption(), '/delivery');
    const { address_list, charter_delivery_address_list } = this.props.profile;
    const newAddressList = delivery_id
      ? delivery_id === 'charter-delivery'
        ? charter_delivery_address_list
        : address_list
      : address_list;
    if (newAddressList && newAddressList.length > 0 && !this.state.selectedAddress) {
      const defaultAddress = newAddressList.find((da) => da.default);
      this.setState({ selectedAddress: defaultAddress || newAddressList[0] });
    } else {
      if (this.props.history.action === 'POP') {
        forwardTo('/dashboard');
      } else {
        const list = this.props.profile.charter_delivery_address_list || [];
        if (list.length === 0) {
          if (!this.state.selectedAddress)
            forwardTo('/delivery-address-check', {
              selectedRestaurant: this.props.location?.state?.selectedRestaurant,
            });
        }
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const order_type = Basket.getOrderType();
    if (order_type === 'charter-delivery') {
      if (this.props.profile.charter_delivery_address_list.length === 0) {
        forwardTo('/delivery-address-check', {
          selectedRestaurant: this.props.location?.state?.selectedRestaurant,
        });
      }
    } else {
      if (this.props.profile.address_list.length === 0) {
        forwardTo('/delivery-address-check', {
          selectedRestaurant: this.props.location?.state?.selectedRestaurant,
        });
      }
    }
    const { restaurants } = this.props;
    if (!this.props.location.state) checkForDeliveryOption(Basket.getDeliveryOption(), '/delivery');
    if (
      this.props.checkedCodeData.length > 0 &&
      prevProps.checkedCodeData !== this.props.checkedCodeData
    ) {
      // if (this.props.checkedCodeData.length === 1) {
      //   let deliveryZone = this.props.checkedCodeData;
      //   const selectedRestaurantId = this.props.checkedCodeData[0].restaurant_id;
      //   this.setState(
      //     {
      //       checking: false,
      //       postalCodeValid: true,
      //       restaurant: this.props.checkedCodeData[0],
      //       deliveryZone,
      //     },
      //     () => {
      //       Basket.setRestaurant(
      //         restaurants.find((restaurant) => restaurant.id === selectedRestaurantId),
      //       );
      //       Basket.setDeliveryAddress(this.state.selectedAddress);
      //       Basket.setDeliveryPrice(deliveryZone[0].delivery_zone.price);
      //       Basket.setMinOrder(deliveryZone[0].delivery_zone.min_order);
      //       this.props.location.state && this.props.location.state.selectedRestaurant
      //         ? forwardTo('/scheduled-delivery-time', {
      //             selectedRestaurant: this.props.location?.state?.selectedRestaurant,
      //           })
      //         : forwardTo('/scheduled-delivery-time');
      //     },
      //   );
      // } else if (this.props.checkedCodeData.length > 1) {
      forwardTo('/delivery-address-check', {
        postCode: this.state.selectedAddress.postalCode,
        selectedRestaurant: this.props.location?.state?.selectedRestaurant,
      });
      // }
    } else if (prevState.checking && this.props.checkedCodeData.length === 0) {
      this.setState({ checking: false, postalCodeValid: false });
    }
  }

  check = (value, type) => {
    Basket.setDeliveryAddress(this.state.selectedAddress);
    if (type === 'postcode') {
      this.props.dispatch(postCodeCheck(value));
      this.setState({ initial: false, checking: true });
    } else if (type === 'distance' || type === 'polygon') {
      this.props.dispatch(getNearestLocation(value));
      this.setState({ initial: false, checking: true, restaurant: {} });
    } else {
      this.setState({ initial: true }, () => {
        this.props.dispatch(setPostCodeData({ data: [] }));
      });
    }
    this.setState({ initial: false, checking: true });
  };

  checkAvailableSlotsForToday = (restaurant) => {
    let today = moment().format('dddd');
    let deliveryTimes = this.props.restaurants.find((r) => r.id === restaurant.restaurant_id)
      .delivery_times_json;
    let flag = false;
    if (!isEmptyObject(deliveryTimes)) {
      deliveryTimes.slots[today].forEach((dt) => {
        let addedTime = null;
        addedTime = moment().add(dt.prep_time, 'hours');
        if (addedTime.isBefore(moment(dt.start_time, 'hh:mm'))) {
          flag = true;
        }
      });
    }
    return flag;
  };

  handleRemoveDeliveryAddress = (data, flag) => {
    this.setState({ removeDeliveryAddress: data });
    this.props.dispatch(setOrdersProp('removeAddressModal', flag));
  };

  removeDeliveryAddress = () => {
    this.props.dispatch(removeDeliveryAddress(this.state.removeDeliveryAddress));
    this.setState({ removeDeliveryAddress: null });
  };

  render() {
    const { __, profile, deliveryRangeType } = this.props;
    const { selectedAddress, deliveryZoneOption, deliveryZone, removeDeliveryAddress } = this.state;
    const { address_list, charter_delivery_address_list } = profile;
    const order_type = Basket.getOrderType();
    const isCharterDelivery =
      isDefined(order_type) && order_type !== '' ? order_type === 'charter-delivery' : false;
    const newAddressList = isCharterDelivery ? charter_delivery_address_list : address_list;
    const hasAddress = newAddressList && newAddressList.length > 0;
    return (
      <Loading>
        <Layout hideSecondToolbar headerTitle={__('Delivery')} headerWithTitle noPadding>
          <div className="flex-row-wrapper absolute-content">
            <div className="scrollable-y">
              <Title className="web-only">{__('Delivery')}</Title>
              {hasAddress ? (
                <>
                  <NormalText>{__('Select your delivery address')}</NormalText>
                  <Spacer />
                  <IonList lines="none" className="box-wrapper box-list">
                    <IonRadioGroup
                      value={JSON.stringify(selectedAddress)}
                      onIonChange={(e) =>
                        this.setState({ selectedAddress: JSON.parse(e.detail.value) })
                      }
                    >
                      {newAddressList.map((da, index) => {
                        return (
                          <IonItem key={'delivery-address-' + index} lines="none">
                            <div tabIndex="-1"></div>
                            <IonRadio
                              color={isWebConfig() ? 'secondary' : 'white'}
                              slot="start"
                              value={JSON.stringify(da)}
                            />
                            <IonButtons slot="end">
                              <IonButton
                                onClick={() => this.handleRemoveDeliveryAddress(index, true)}
                                className="link"
                                color="primary"
                              >
                                <IonIcon icon={trash}></IonIcon>
                              </IonButton>
                            </IonButtons>
                            <IonLabel>
                              <SmallText>{da.addressLine1}</SmallText>
                              <br />
                              <NormalText color="black">{da.postalCode}</NormalText>
                            </IonLabel>
                          </IonItem>
                        );
                      })}
                    </IonRadioGroup>
                  </IonList>
                </>
              ) : null}
              <IonButton
                fill="clear"
                className="link underlined"
                color="dark"
                onClick={() => {
                  forwardTo('/delivery-address-check');
                }}
              >
                {__((hasAddress ? 'Or add another' : 'Add') + ' delivery address')}
              </IonButton>
            </div>
            <div className="flex-min">
              <IonButton
                disabled={!selectedAddress}
                expand="block"
                color="primary"
                onClick={() =>
                  this.check(selectedAddress ? selectedAddress.postalCode : '', deliveryRangeType)
                }
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
        </Layout>
        <IonAlert
          isOpen={this.props.removeAddressModal === true}
          onDidDismiss={() => this.handleRemoveDeliveryAddress(null, false)}
          header={__('Confirm')}
          message={__('Do you you want to remove this delivery address?')}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: __('Remove'),
              handler: () => this.removeDeliveryAddress(),
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile } = state.profile;
  const { deliveryOption, checkedCodeData, removeAddressModal } = state.orders;
  const { deliveryRangeType } = state.common;
  const { restaurants } = state.restaurants;
  return {
    profile,
    deliveryOption,
    deliveryRangeType,
    checkedCodeData,
    restaurants,
    removeAddressModal,
  };
};

export default connect(stateToProps)(withTranslation(Delivery));
