import React from 'react';

import './index.css';

const PillStatus = (props) => {
  const { item, children, className } = props;
  const content = children ? children : typeof item === 'string' ? item : item.text;
  const action = typeof item === 'string' ? null : item.action || null;
  const actionAttr = action ? { onClick: action } : {};
  return (
    <div
      className={
        'pill-status ellipsis' + (className ? ' ' + className : '') + (action ? ' pill-action' : '')
      }
      {...actionAttr}
    >
      {content}
    </div>
  );
};

export const PillStatusGroup = (props) => {
  const { items, className, ...rest } = props;
  return (
    <div className={'pill-status-group' + (className ? ' ' + className : '')}>
      {items.map((item, index) => (
        <PillStatus key={'pill-status' + index} item={item} {...rest} />
      ))}
    </div>
  );
};

export default PillStatus;
