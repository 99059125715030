import React from 'react';
import { connect } from 'react-redux';
import {
  IonCard,
  IonCardContent,
  IonIcon,
  IonItem,
  IonInput,
  IonButton,
  IonSpinner,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
  IonTextarea,
  IonModal,
} from '@ionic/react';
import { closeCircle, checkmarkCircle, chevronDown, chevronUp, menu } from 'ionicons/icons';
import Layout from '../../components/layout';
import { Title, StrongText, SmallText, Spacer, NormalText } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import {
  forwardTo,
  forwardToDeliveryOption,
  sprintf,
  isEmptyObject,
  isWebConfig,
} from '../../lib/utils';
import {
  setDeliveryAddress,
  postCodeCheck,
  setPostCodeData,
  getNearestLocation,
  setCommonModal,
  showToast,
  addDeliveryAddress,
  storeDeliveryAddress,
} from '../../store/actions';
import Basket from '../../lib/basket';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

import '../clickAndCollect/index.css';
import './index.css';
import { getConfig } from '../../appConfig';
import Modal from '../../components/modal';

// const dummyValidAddressFromPostalCode = {
// 	addressLine1: 'Wilton Road'
// }

class DeliveryAddressCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        addressLine1: '',
        addressLine2: '',
        place: '',
        postalCode: '',
        driverNotes: '',
        companyName: '',
      },
      deliveryMenus: [],
      chooseMenuModalOpen: false,
      usePostCode: props.location.state && props.location.state.postCode ? true : false,
      initial: true,
      checking: false,
      postalCode:
        props.location.state && props.location.state.postCode ? props.location.state.postCode : '',
      address: '',
      postalCodeValid: false,
      addressValid: true,
      deliveryZone: [],
      deliveryZoneOption: {},
      deliveryPrice: '',
      restaurant: {},
      validPostCode: false,
      checkMarkFlag: null,
      minOrder: 0,
      isPostCode: true,
      searchOptions: {
        componentRestrictions: {
          country: 'UK',
        },
        types: ['address'],
      },
      menuDetailsArr: [],
      selectedMenu: null,
    };
  }
  toggleMenuDetails = (index) => {
    const menuDetailsArr = JSON.parse(JSON.stringify(this.state.menuDetailsArr));
    if (menuDetailsArr.includes(index)) {
      this.setState({ menuDetailsArr: menuDetailsArr.filter((item) => item != index) });
    } else {
      this.setState({ menuDetailsArr: [...menuDetailsArr, index] });
    }
  };
  handleChange = (address) => {
    this.setState(
      {
        address,
        deliveryZone: [],
        deliveryZoneOption: {},
        form: {
          addressLine1: '',
          addressLine2: '',
          place: '',
          postalCode: '',
          driverNotes: '',
          companyName: '',
        },
        deliveryPrice: '',
        restaurant: {},
      },
      () => {
        this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false));
        this.props.dispatch(setPostCodeData({ data: [] }));
      },
    );
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        if (
          results[0].address_components.length >= 7 &&
          results[0].address_components[0].types.includes('street_number')
        ) {
          let city = '';
          results[0].address_components.map((el) => {
            if (el.types.includes('postal_town')) {
              city = el.long_name;
              return true;
            }
          });
          this.setState({
            addressValid: true,
            isPostCode: true,
            address: results[0].formatted_address,
            form: {
              addressLine1: `${results[0].address_components[0].long_name} ${results[0].address_components[1].long_name}`,
              place: city ? city : results[0].address_components[2].long_name,
              postalCode:
                results[0].address_components[results[0].address_components.length - 1].long_name,
            },
          });
          this.check(
            results[0].address_components[results[0].address_components.length - 1].long_name,
            this.props.deliveryRangeType,
          );
        } else if (
          results[0].address_components.length >= 7 &&
          results[0].address_components[0].types.includes('premise')
        ) {
          const formatedResponse = this.formatGoogleMapResponse(results);
          if(!isEmptyObject(formatedResponse) ){
            this.setState({
              addressValid: true,
              isPostCode: true,
              address: results[0].formatted_address,
              form: {
                addressLine1: `${formatedResponse.street_number} ${formatedResponse.route}`,
                place: formatedResponse.postal_town || formatedResponse.neighborhood,
                postalCode: formatedResponse.postal_code,
              },
            });
            this.check(
              formatedResponse.postal_code,
              this.props.deliveryRangeType,
            );
          }else {
            this.setState({
              address: results[0].formatted_address,
              addressValid: false,
              isPostCode: false,
            });
          }
        } else if (
          results[0].address_components.length == 6 &&
          results[0].address_components[0].types.includes('street_number')
        ) {
          this.setState({
            address: results[0].formatted_address,
            addressValid: true,
            isPostCode: false,
            form: {
              addressLine1: `${results[0].address_components[0].long_name} ${results[0].address_components[1].long_name}`,
              place: results[0].address_components[2].long_name,
            },
          });
        } else {
          this.setState({
            address: results[0].formatted_address,
            addressValid: false,
            isPostCode: false,
          });
        }
      })
      .catch((error) => console.error('Error', error));
  };

  checkDelivery = () => {
    if (!Basket.getDeliveryOption()) {
      forwardToDeliveryOption();
    }
  };

  componentDidMount() {
    this.checkDelivery();
    if (
      this.props.location.state &&
      this.props.location.state.postCode
      // this.props.checkedCodeData.length > 1
    ) {
      let deliveryZone = this.props.checkedCodeData;
      const priority = getConfig()?.priority || [];
      deliveryZone = deliveryZone.map((resturant) => {
        const resturanPriority = priority.find(
          (priorityResturant) => priorityResturant.resturantId === resturant.restaurant_id,
        );
        resturant.priority = resturanPriority?.resturantPriority || 2;
        return resturant;
      });
      this.setState(
        { checking: false, postalCodeValid: true, deliveryZone, checkMarkFlag: 'success' },
        () => {
          if (this.props.location.state && this.props.location.state.postCode)
            this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', true));
        },
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.checkDelivery();
    if (
      this.props.checkedCodeData.length > 0 &&
      prevProps.checkedCodeData !== this.props.checkedCodeData
    ) {
      let deliveryZone = this.props.checkedCodeData;
      this.setState(
        { checking: false, postalCodeValid: true, deliveryZone, checkMarkFlag: 'success' },
        () => {
          this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', true));
        },
      );
    } else if (prevState.checking && this.props.checkedCodeData.length === 0) {
      this.setState({ checking: false, postalCodeValid: false, checkMarkFlag: 'danger' });
    }
  }

  checkPostCode = (value) => {
    // let reg = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/
    // let postalCodeValid = reg.test(value)
    if (value.length >= 3) {
      this.setState({
        postalCodeValid: true,
        postalCode: value,
        initial: false,
        checkMarkFlag: null,
      });
    } else {
      this.setState(
        { postalCodeValid: false, postalCode: value, initial: false, checkMarkFlag: null },
        () => {
          this.props.dispatch(setPostCodeData({ data: [] }));
        },
      );
    }
  };

  setPostalCode = (e) => {
    this.checkPostCode(e.target.value);
  };

  check = (value, type) => {
    if (type === 'postcode') {
      this.props.dispatch(postCodeCheck(value));
      this.setState({ initial: false, checking: true });
      this.setState({ form: { ...this.state.form, postalCode: value } });
    } else if (type === 'distance' || type === 'polygon') {
      this.props.dispatch(getNearestLocation(value));
      this.setState({ initial: false, checking: true, restaurant: {}, deliveryPrice: '' });
    } else {
      this.setState({ initial: true }, () => {
        this.props.dispatch(setPostCodeData({ data: [] }));
      });
    }
    this.setState({ initial: false, checking: true });
  };

  saveAndContinue = (menu) => {
    const { postalCode, restaurant } = this.state;
    const { restaurants, profile } = this.props;
    const selectedRestaurant =
      this.props.location?.state?.selectedRestaurant ||
      this.props.restaurants.find((res) => restaurant.restaurant_id == res.id);
    if (menu) {
      Basket.setDeliveryMenu(menu);
    } else {
      Basket.setDeliveryMenu(selectedRestaurant.active_menu?.scheduled_delivery[0]);
    }
    const minOrder = restaurant.delivery_zone.min_order;
    if (
      this.props.location.state &&
      this.props.location.state.postCode
      // this.props.checkedCodeData.length > 1
    ) {
      forwardTo('/scheduled-delivery-time', {
        selectedRestaurant: this.props.location?.state?.selectedRestaurant,
      });
    } else {
      this.props.dispatch(setDeliveryAddress({ postalCode: postalCode.toUpperCase() }));
      forwardTo('/delivery-address-add', {
        selectedRestaurant: this.props.location?.state?.selectedRestaurant,
      });
    }
    Basket.setRestaurant(restaurants.find((res) => res.id === restaurant.restaurant_id));
    Basket.setDeliveryPrice(this.state.deliveryPrice);
    Basket.setMinOrder(minOrder);
    if (!Basket.getDeliveryAddress() || isEmptyObject(Basket.getDeliveryAddress())) {
      forwardTo('/delivery-address-add', {
        selectedRestaurant: this.props.location?.state?.selectedRestaurant,
      });
    } else {
      forwardTo('/scheduled-delivery-time', {
        selectedRestaurant: this.props.location?.state?.selectedRestaurant,
      });
    }
  };
  saveAddressAndContinue = (menu) => {
    const { __, restaurants, auth, profile, dispatch } = this.props;
    const { restaurant } = this.state;
    const minOrder = restaurant.delivery_zone.min_order;
    const order_type = Basket.getOrderType();

    if (auth && auth.loggedIn) {
      let found = !!(order_type === 'charter-delivery'
        ? profile.charter_delivery_address_list
          ? profile.charter_delivery_address_list
          : []
        : profile.address_list
          ? profile.address_list
          : []
      ).find(
        (al) =>
          this.state.form.addressLine1 + this.state.form.place + this.state.form.postalCode ===
          al.addressLine1 + al.place + al.postalCode,
      );
      if (!found) {
        this.props.dispatch(
          addDeliveryAddress({ ...this.state.form, order_type: Basket.getOrderType() }),
        );
      } else {
        dispatch(showToast(__('Address already exist'), 'warning'));
      }
    } else {
      this.props.dispatch(storeDeliveryAddress(this.state.form));
    }
    const selectedRestaurant =
      this.props.location?.state?.selectedRestaurant ||
      this.props.restaurants.find((res) => restaurant.restaurant_id == res.id);

    if (menu) {
      Basket.setDeliveryMenu(menu);
    } else {
      Basket.setDeliveryMenu(selectedRestaurant.active_menu?.scheduled_delivery[0]);
    }
    Basket.setDeliveryAddress(this.state.form);
    Basket.setRestaurant(restaurants.find((res) => res.id === restaurant.restaurant_id));
    Basket.setDeliveryPrice(this.state.deliveryPrice);
    Basket.setMinOrder(minOrder);
    forwardTo('/scheduled-delivery-time', {
      selectedRestaurant: this.props.location?.state?.selectedRestaurant,
    });
  };

  restaurantName = (restaurant) => {
    if (restaurant && restaurant['restaurant_id']) {
      return restaurant.restaurant_name;
    }
    return '';
  };

  restaurantAddress = (restaurant) => {
    if (restaurant && restaurant['restaurant_id']) {
      return restaurant.restaurant_address;
    }
    return '';
  };

  changeDeliveryZone = (event) => {
    this.setState({ deliveryZoneOption: event.detail.value, error: '' });
    if (event.detail.value) {
      let deliveryZone = event.detail.value
      let deliveryPrice = deliveryZone?.delivery_zone?.price;
      let minOrder = deliveryZone?.delivery_zone?.min_order;
      this.setState(
        { deliveryPrice, minOrder, restaurant: deliveryZone },
        () => {
          this.props.dispatch(setCommonModal('isChooseDeliveryModalOpen', false));
        },
      );
    }
  };

  setCompanyName = (e) => {
    let form = { ...this.state.form };
    form.companyName = e.target.value;
    this.setState({ form });
  };
  setDriverNotes = (e) => {
    let form = { ...this.state.form };
    form.driverNotes = e.target.value;
    this.setState({ form });
  };
  chooseMenu = (event) => {
    this.setState({ selectedMenu: event.detail.value });
  };

  formatGoogleMapResponse = (results)=>{
    const formatedResponse = {};
    if(results && results[0]?.address_components && results[0].address_components.length > 0){
      results[0].address_components.map((el) => {
        if (el.types.includes('postal_town')) {
          formatedResponse.postal_town = el.long_name;
        }else if (el.types.includes('street_number')) {
          formatedResponse.street_number = el.long_name;
        }else if (el.types.includes('route')) {
          formatedResponse.route = el.long_name;
        }else if (el.types.includes('neighborhood')) {
          formatedResponse.neighborhood = el.long_name;
        }else if (el.types.includes('postal_code')) {
          formatedResponse.postal_code = el.long_name;
        }
      });
    }
    return formatedResponse;
  }

  render() {
    const {
      __,
      checkedCodeData,
      isChooseDeliveryModalOpen,
      deliveryRangeType,
      restaurants,
    } = this.props;
    const {
      initial,
      checking,
      postalCode,
      address,
      postalCodeValid,
      deliveryZone,
      deliveryZoneOption,
      deliveryPrice,
      restaurant,
      checkMarkFlag,
      minOrder,
      searchOptions,
      addressValid,
      isPostCode,
      form,
    } = this.state;
    const animationMenuClass = isChooseDeliveryModalOpen ? 'show-up' : '';
    const deliveryOption = Basket.getDeliveryOption();
    const selectedRestaurant =
      this.props.location?.state?.selectedRestaurant ||
      this.props.restaurants.find((res) => restaurant.restaurant_id == res.id);
      deliveryZone.forEach(restaurant => {
        const priority = getConfig().priority.find(item => item.resturantId === restaurant.restaurant_id);
        restaurant.sort = priority ? priority.resturantPriority : 100;
    });
    deliveryZone.sort((a,b)=>a.sort-b.sort)
    return (
      <Layout
        hideSecondToolbar
        headerWithTitle
        headerTitle={__(deliveryOption ? deliveryOption.label : '')}
        color="transparent"
      >
        <div className="absolute-content delivery-address-bg"></div>
        <IonCard color="white" className="restaurant-card">
          <IonCardContent className="flex-row-wrapper">
            <div className="flex-min">
              <Title className="web-only">{__('Order for Office Delivery')}</Title>
              <NormalText className='block'>{__('There are two ways to order.')}</NormalText>
              <NormalText className='block'>{__('Choose Pure Catering Hub for catering platters and buffet items to share, and large order of individual items.')}</NormalText>
              <Spacer size={1}/>

              <NormalText className='block'> {__('Or choose your local Pure for individual menu items only (No platters at local shops).')}</NormalText>
              <Spacer size={1}/>

              <NormalText className='block'>{__('Delivery charge is inclusive of VAT.')}</NormalText>
              <Spacer size={1}/>



            </div>
            <div>
              {this.state.usePostCode ? (
                <div className="input-field-container">
                  <NormalText>{__('Postcode')}</NormalText>
                  <IonItem className="input-field-wrapper" lines="none">
                    <IonInput
                      className="strong-text uppercase"
                      required={true}
                      value={postalCode}
                      onIonChange={this.setPostalCode}
                      type="text"
                    >
                      {initial || checking || !checkMarkFlag ? null : (
                        <IonIcon
                          size="small"
                          color={checkMarkFlag}
                          icon={checkMarkFlag === 'success' ? checkmarkCircle : closeCircle}
                        />
                      )}
                    </IonInput>
                  </IonItem>
                </div>
              ) : (
                <PlacesAutocomplete
                  value={address}
                  onChange={this.handleChange}
                  onSelect={this.handleSelect}
                  searchOptions={searchOptions}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className="input-field-container">
                      <NormalText>{__('Full Address (including building number)')}</NormalText>
                      <IonItem lines="none" className="input-field-wrapper">
                        <input
                          {...getInputProps({
                            placeholder: 'Search Places ...',
                            className:
                              'location-search-input native-input sc-ion-input-md .sc-ion-label-md-h-custom',
                          })}
                        />
                      </IonItem>

                      <div className="autocomplete-dropdown-container ">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, index) => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                          return (
                            <div
                              className="pac-container pac-logo"
                              key={index}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <div className="pac-item">
                                <span className="pac-icon pac-icon-marker"></span>
                                <span className="pac-item-query">
                                  <span>{suggestion.description}</span>
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              )}
              {this.state.usePostCode ? (
                <IonButton
                  fill="clear"
                  className="link underlined"
                  color="dark"
                  onClick={() => {
                    if (postalCodeValid) {
                      this.setState({
                        postalCodeValid: false,
                        restaurant: {},
                        postalCode: '',
                        checkMarkFlag: false,
                        deliveryZone: [],
                      });
                      this.props.dispatch(setPostCodeData({ data: [] }));
                    }
                    this.setState({
                      usePostCode: false,
                    });
                  }}
                >
                  {checkedCodeData.length !== 0 && checkMarkFlag
                    ? __('Back')
                    : __('Check by address?')}
                </IonButton>
              ) : isEmptyObject(restaurant) && !isChooseDeliveryModalOpen ? (
                <IonButton
                  fill="clear"
                  className="link underlined"
                  color="dark"
                  onClick={() => {
                    this.setState({
                      usePostCode: true,
                    });
                  }}
                >
                  {__('Address not listed?')}
                </IonButton>
              ) : null}
              <Spacer size="1" />
              <div
                className={
                  'address-checking-box' + (addressValid && !isPostCode ? '' : ' centered')
                }
              >
                {!addressValid && !isPostCode && !this.state.usePostCode ? (
                  <SmallText color="danger">
                    {sprintf(
                      __('Please write the full address in correct form including number'),
                      'small',
                    )}
                  </SmallText>
                ) : addressValid && !isPostCode ? (
                  <>
                    <IonLabel color="dark" position="floating">
                      {__('Please provide postcode')}
                    </IonLabel>
                    <IonInput
                      required={true}
                      value={postalCode}
                      onIonChange={this.setPostalCode}
                      type="text"
                    >
                      {initial || checking || !checkMarkFlag ? null : (
                        <IonIcon
                          size="small"
                          color={checkMarkFlag}
                          icon={checkMarkFlag === 'success' ? checkmarkCircle : closeCircle}
                        />
                      )}
                    </IonInput>
                  </>
                ) : null}
                <div
                  // style={{ display: deliveryZone.length > 1 ? '' : 'none' }}
                  className="ion-text-start"
                >
                  <IonList lines="none" className="box-wrapper box-list">
                    <IonRadioGroup onIonChange={this.changeDeliveryZone} value={deliveryZoneOption}>
                      {deliveryZone
                        .sort((a, b) => {
                          return a.delivery_zone?.priority - b.delivery_zone?.priority;
                        })
                        .map((restaurant, i) => {
                          const { restaurant_name } = restaurant;
                          const { price, min_order } = restaurant.delivery_zone;
                          return (
                            <IonItem lines="none" key={i}>
                              <div tabIndex="-1"></div>
                              <IonRadio
                                color={isWebConfig() ? 'secondary' : 'white'}
                                slot="start"
                                value={restaurant}
                              />
                              <div>
                                <IonLabel className="ion-text-wrap ion-text-start" color="dark">
                                  {restaurant_name}
                                </IonLabel>
                                <IonLabel className="ion-text-wrap ion-text-start" color="primary">
                                  {price > 0
                                    ? __('Delivery Charge:') + Basket.formatPrice(price)
                                    : __('Free Delivery')}
                                </IonLabel>
                                <IonLabel className="ion-text-wrap ion-text-start" color="primary">
                                  {'Minimum Order: ' + Basket.formatPrice(min_order)}
                                </IonLabel>
                              </div>
                            </IonItem>
                          );
                        })}
                    </IonRadioGroup>
                  </IonList>
                </div>
                {initial ? null : checking ? (
                  <>
                    <br />
                    <div>
                      <IonSpinner />
                    </div>
                    <SmallText>{__('Checking nearest locations')}</SmallText>
                  </>
                ) : postalCodeValid && checkedCodeData.length > 0 ? (
                  isEmptyObject(restaurant) ||
                    (!checkMarkFlag && !addressValid && !isPostCode) ? null : (
                    <>
                      <Spacer size={1} />
                      <div className="input-field-container">
                        <NormalText>{__('Company Name (Optional)')}</NormalText>
                        <IonItem className="input-field-wrapper" lines="none">
                          <IonInput
                            value={form.companyName}
                            onIonChange={this.setCompanyName}
                            type="text"
                          />
                        </IonItem>
                      </div>
                      <div className="input-field-container">
                        <NormalText>{__('Driver Notes')}</NormalText>
                        <IonItem className="input-field-wrapper" lines="none">
                          <IonTextarea
                            className="strong-text uppercase custom-border native-input sc-ion-input-md lefted"
                            value={form.driverNotes}
                            onIonChange={this.setDriverNotes}
                            type="text"
                            clearInput
                          />
                        </IonItem>
                      </div>
                      <Spacer size={1} />
                      <SmallText>{__('Your order will be delivered from:')}</SmallText>
                      <Spacer size={1} />
                      <SmallText>{this.restaurantName(restaurant)}</SmallText>
                      <Spacer size={1} />
                      {deliveryPrice > 0 ? (
                        <SmallText>
                          {sprintf(
                            __('A small delivery charge of ') +
                            Basket.formatPrice(deliveryPrice) +
                            __(' will apply.'),
                            'small',
                          )}{' '}
                          {sprintf(__('Minimum order ' + Basket.formatPrice(minOrder)), 'small')}{' '}
                        </SmallText>
                      ) : (
                        <SmallText>
                          {sprintf(__('There is no delivery charge from this location'), 'small')}
                        </SmallText>
                      )}
                    </>
                  )
                ) : checkedCodeData.length === 0 && !checkMarkFlag ? null : (
                  <>
                    <Spacer />
                    <SmallText color="danger">
                      {__("Unfortunately, we don't deliver to you yet")}
                    </SmallText>
                  </>
                )}
              </div>
            </div>
            <Spacer />
            <div className="flex-min">
              {postalCodeValid && checkedCodeData.length > 0 && checkMarkFlag ? (
                this.state.usePostCode ? (
                  <IonButton
                    disabled={!postalCodeValid || deliveryPrice === ''}
                    expand="block"
                    color="primary"
                    onClick={() =>
                      selectedRestaurant.active_menu?.scheduled_delivery.length > 1
                        ? this.setState({
                          newAddress: false,
                          deliveryMenus: selectedRestaurant.active_menu?.scheduled_delivery,
                          chooseMenuModalOpen: true,
                        })
                        : this.saveAndContinue()
                    }
                  >
                    {__('Continue')}
                  </IonButton>
                ) : (
                  <IonButton
                    disabled={!postalCodeValid || deliveryPrice === ''}
                    expand="block"
                    color="primary"
                    onClick={() =>
                      selectedRestaurant.active_menu?.scheduled_delivery.length > 1
                        ? this.setState({
                          newAddress: true,
                          deliveryMenus: selectedRestaurant.active_menu?.scheduled_delivery,
                          chooseMenuModalOpen: true,
                        })
                        : this.saveAddressAndContinue()
                    }
                  >
                    {__('Save address and Continue')}
                  </IonButton>
                )
              ) : this.state.usePostCode || (addressValid && !isPostCode) ? (
                <IonButton
                  className="no-margin"
                  disabled={!postalCodeValid}
                  expand="block"
                  color="primary"
                  onClick={() => {
                    Basket.setDeliveryAddress({});
                    this.check(postalCode, deliveryRangeType);
                  }}
                >
                  {__('Check Postcode')}
                </IonButton>
              ) : null}
            </div>
          </IonCardContent>
        </IonCard>
        <Modal
          isOpen={this.state.chooseMenuModalOpen}
          onDidDismiss={() => this.setState({ chooseMenuModalOpen: false })}
        >
          <div>
            <Title>{__('Choose menu')}</Title>
            <Spacer />
            <IonList lines="none" className="box-wrapper menus-list">
              <IonRadioGroup onIonChange={this.chooseMenu} value={this.state.selectedMenu}>
                {this.state.deliveryMenus.map((menu, i) => {
                  return (
                    <div className="menu-wrapper">
                      <div>
                        <IonItem key={menu.menu_id} lines="none">
                          <div tabIndex="-1"></div>
                          <IonRadio color="primary" slot="start" value={menu} />
                          <IonLabel className="ion-text-wrap" color="dark">
                            {menu.menu_id}
                          </IonLabel>
                        </IonItem>
                        <IonIcon
                          onClick={() => this.toggleMenuDetails(i)}
                          icon={this.state.menuDetailsArr.includes(i) ? chevronUp : chevronDown}
                        ></IonIcon>
                      </div>
                      {this.state.menuDetailsArr.includes(i) && (
                        <div className="menu-times">
                          {Object.keys(menu.slots).map((slot) => (
                            <>
                              {menu.slots[slot].start_time ? (
                                <NormalText className="block capitalize">
                                  <NormalText className="menu-day secondary-color">
                                    {__(slot.slice(0, 3))}:
                                  </NormalText>{' '}
                                  <NormalText>
                                    {menu.slots[slot].start_time}-{menu.slots[slot].end_time}
                                  </NormalText>
                                </NormalText>
                              ) : null}
                            </>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                })}
              </IonRadioGroup>
            </IonList>
            <Spacer />
            <IonButton
              onClick={() =>
                this.state.newAddress
                  ? this.saveAddressAndContinue(this.state.selectedMenu)
                  : this.saveAndContinue(this.state.selectedMenu)
              }
              disabled={!this.state.selectedMenu}
              color="primary"
              expand="block"
            >
              {__('Continue')}
            </IonButton>
          </div>
        </Modal>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { profile, auth } = state.profile;
  const { checkedCodeData } = state.orders;
  const { deliveryRangeType, isChooseDeliveryModalOpen } = state.common;
  const { restaurants } = state.restaurants;
  return {
    profile,
    auth,
    checkedCodeData,
    deliveryRangeType,
    isChooseDeliveryModalOpen,
    restaurants,
  };
};

export default connect(stateToProps)(withTranslation(DeliveryAddressCheck));
