import React from 'react';
import { withRouter } from 'react-router';
import {
  IonHeader,
  IonToolbar,
  IonMenuButton,
  IonButtons,
  IonButton,
  IonIcon,
  IonBadge,
} from '@ionic/react';
import { scan, basket } from 'ionicons/icons';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import Icon from '../icon';
import BackButton from '../backButton';
import { forwardTo, getRouteClassName } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { getConfig } from '../../appConfig';
import './index.css';
import { Title } from '../common';

const StaticHeader = ({
  __,
  auth,
  profile,
  history,
  backHandler,
  title,
  headerWithTitle,
  showHamburgerIcon,
  navConfig,
  coloredDashboard,
}) => {
  const currentPath = history.location.pathname;
  const authPages = getConfig().general.authRoutes.indexOf(currentPath) !== -1;
  const hideStaticHeader = getConfig().theme.routesWithoutStaticHeader.indexOf(currentPath) !== -1;
  const routeClassName = getRouteClassName(currentPath);

  return (
    <>
      {hideStaticHeader ? null : getConfig().theme.showHeaderOnAuthRoutes || !authPages ? (
        <IonHeader className={'static ' + routeClassName}>
          <IonToolbar
            className={`${headerWithTitle ? 'no-logo' : ''} primary-toolbar ${
              coloredDashboard ? 'primary-toolbar-colored' : ''
            }`}
          >
            <IonButtons slot="start">
              {!authPages && showHamburgerIcon ? (
                <IonMenuButton color={coloredDashboard ? 'white' : 'dark'} />
              ) : (
                <>
                  <BackButton backHandler={backHandler} />
                </>
              )}
            </IonButtons>
            {!headerWithTitle ? (
              <IonButtons
                style={{
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  position: 'absolute',
                  width: '100%',
                  zIndex: '-1',
                }}
                color={coloredDashboard ? 'white' : 'dark'}
              >
                <IonButton
                  className={`image-button ${coloredDashboard ? 'image-button-white' : ''}`}
                  onClick={() => forwardTo('/dashboard')}
                />
              </IonButtons>
            ) : (
              <IonButton className="header-title" onClick={() => forwardTo('/dashboard')}>
                <Title>{title}</Title>
              </IonButton>
            )}
            <IonButtons slot="end">
              {Basket.getOrderType() !== '' ? (
                <IonButton
                  disabled={Basket.itemsCount() > 0 ? false : true}
                  color="dark"
                  className="basket-button"
                  onClick={() => forwardTo('/order-summary')}
                  size={24}
                >
                  <div>
                    <div
                      className="basket-icon-wrapper"
                      style={{ color: `${coloredDashboard ? 'white' : 'dark'}` }}
                    >
                      <IonBadge
                        slot="end"
                        color="primary"
                        className={Basket.itemsCount() >= 10 ? 'badge' : 'badge-small'}
                      >
                        {Basket.itemsCountAll()}
                      </IonBadge>
                      <Icon icon={basket} classname="icon" />
                    </div>
                    <div>{/* <SmallText color="gray">
													{ Basket._getTotal() }
												</SmallText> */}</div>
                  </div>
                </IonButton>
              ) : !authPages ? (
                <IonButton
                  color="dark"
                  button
                  clear
                  onClick={() => forwardTo('/loyalty', { tab: 'scan' })}
                >
                  <IonIcon slot="icon-only" icon={scan} />
                </IonButton>
              ) : null}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      ) : null}
    </>
  );
};

const stateToProps = (store) => {
  const { orders } = store;
  return {
    basketUpdated: orders.basketUpdated,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(StaticHeader)));
