import { call, put, select, takeEvery } from 'redux-saga/effects';
import api from '../../lib/api';
import { GET_PROFILE, SET_PROFILE_PROP, UPDATE_PROFILE } from '../profile/constants';
import {
  CANCEL_USER_SUBSCRIPTION,
  FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS_OK,
  PAY_MEMBERSHIP,
  REACTIVATE_USER_SUBSCRIPTION,
  PAY_MEMBERSHIP_OK,
  PAY_MEMBERSHIP_FAILED,
  CREATE_SUBSCRIPTION,
  CREATE_SUBSCRIPTION_NATIVE
} from './constants';
import { forwardTo, getAppType, isEmptyObject } from '../../lib/utils';
import { showToast } from '../actions';
import { loading, translateSaga } from '../common/sagas';
import { getConfig } from '../../appConfig';
import LibStripe from '../../lib/stripe';
import { PaymentFlowEventsEnum } from '@capacitor-community/stripe';

const getSubscriptionsSaga = function* () {
  const subscriptions = yield call(api.getSubscriptions);
  yield put({ type: GET_PROFILE });
  yield put({ type: FETCH_SUBSCRIPTIONS_OK, key: 'subscriptions', value: subscriptions });
};

const createSubscriptionSaga = function* (action) {
  try {
    const response = yield call(api.getProfile);
    const profile = response.data.profile;
    const subscriptionLocationId = getConfig().general?.subscriptionLocationId || '009';
    if (isEmptyObject(profile.active_subscription)) {
      const result = yield call(api.createSubscription, {
        data: action.value,
        location: subscriptionLocationId,
      });
      window.open(result.data.checkout.url, '_self');
    } else {
      yield put(showToast(yield call(translateSaga, 'Subscription is already created'), 'success'));
      yield call(forwardTo, '/my-membership');
    }
  } catch (error) {
    yield put(showToast(error.response.data.status, 'danger'));
  }
};

const createSubscriptionNativeSaga = function* (action) {
  try {
    yield call(loading, function* () {
      const config = yield call(api.getFrontEndAppConfig);
      const result = yield call(api.createSubscriptionNative, {
        data: action.value,
        location: config?.front_end_app_config?.general?.membershipLocationId || 0,
      });
      if (!result || result.data?.error || result.error) {
        yield put(showToast('Error creating subscription', 'warning'));
      } else {
        try {
          const nativePayment = getConfig().nativePayment || {};
          const merchantDisplayName = nativePayment.merchantDisplayName || "";
          const merchantCountryCode = nativePayment.merchantCountryCode || 'GB';
          const withZipCode = nativePayment.withZipCode || false;
          const enableGooglePay = nativePayment.enableGooglePay || false;
          const googlePayIsTesting = nativePayment.googlePayIsTesting || false;
          const enableApplePay = nativePayment.enableApplePay || false;
          const applePayMerchantId = nativePayment.applePayMerchantId || '';
          const stripeInstance = LibStripe.getStripeInstance();
          const customerId = result.data?.customer_id;
          const customerEphemeralKeySecret = result.data?.ephemeral_key;
          const paymentIntentClientSecret = result.data?.intent_client_secret;
          const setupIntentClientSecret = result.data?.setup_intent_client_secret;
          const haveFreeTrial = result.data?.have_free_trial;
          const setup_intent_id = result.data?.setup_intent_id;
          const paymentSheetConfig = {
            GooglePayIsTesting: googlePayIsTesting,
            merchantDisplayName,
            merchantCountryCode,
            withZipCode,
            enableGooglePay,
            enableApplePay,
            applePayMerchantId,
            customerId,
            customerEphemeralKeySecret
          };
          if (haveFreeTrial) {
            paymentSheetConfig.setupIntentClientSecret = setupIntentClientSecret;
            yield stripeInstance.createPaymentSheet(paymentSheetConfig);
            const { paymentResult } = yield stripeInstance.presentPaymentSheet();
            if (paymentResult === 'paymentSheetCompleted') {
              const confirmStatus = yield call(api.confirmTrialSubscription, {
                data: action.value,
                location: config?.front_end_app_config?.general?.membershipLocationId || 0,
                setup_intent_client_secret: setupIntentClientSecret,
                setup_intent_id: setup_intent_id
              });
              if(confirmStatus && confirmStatus?.data?.subscription_is_created){
                forwardTo('/my-membership');
              } else {
                forwardTo('/membership');
                return null;
              }
            } else {
              forwardTo('/membership');
              return null;
            }
          } else {
            paymentSheetConfig.paymentIntentClientSecret = paymentIntentClientSecret;
            yield stripeInstance.createPaymentSheet(paymentSheetConfig);
            const { paymentResult } = yield stripeInstance.presentPaymentSheet();
            if (paymentResult === 'paymentSheetCompleted') {
              forwardTo('/my-membership');
            } else {
              forwardTo('/membership');
              return null;
            }
          }
        } catch (ex) {
          console.log('Error creating native subscription: ', ex);
          forwardTo('/membership');
          return null;
        }
      }
    });

  } catch (error) {
    yield put(showToast(error.response.data.status, 'danger'));
  }
};

const cancelSubscriptionSaga = function* () {
  const { profile } = yield select();
  profile.profile.active_subscription = {
    ...profile.profile.active_subscription,
    cancellation_pending: true,
  };
  const result = yield call(api.cancelUserSubscription, { cancel: true });
  if (result.status === 201) {
    yield put({ type: SET_PROFILE_PROP, key: 'profile', value: profile.profile });
    yield put({
      type: UPDATE_PROFILE,
      data: { active_subscription: { cancellation_pending: true } },
    });
  }
};

const reactivateSubscriptionSaga = function* () {
  const { profile } = yield select();
  profile.profile.active_subscription = {
    ...profile.profile.active_subscription,
    cancellation_pending: false,
  };
  const result = yield call(api.cancelUserSubscription, { cancel: false });
  if (result.status === 201) {
    yield put({ type: SET_PROFILE_PROP, key: 'profile', value: profile.profile });
    yield put({
      type: UPDATE_PROFILE,
      data: { active_subscription: { cancellation_pending: false } },
    });
  }
};

const payMembershipSaga = function* (action) {
  try {
    const data = yield call(api.payMembership, action.value);
    yield put({ type: PAY_MEMBERSHIP_OK, data });
    yield put({ type: GET_PROFILE });
    yield call(forwardTo, '/membership-completed', { completedOrder: true });
  } catch (error) {
    yield put({ type: PAY_MEMBERSHIP_FAILED, error });
  }
};

export default function* subscriptionSagas() {
  yield takeEvery(FETCH_SUBSCRIPTIONS, getSubscriptionsSaga);
  yield takeEvery(CANCEL_USER_SUBSCRIPTION, cancelSubscriptionSaga);
  yield takeEvery(REACTIVATE_USER_SUBSCRIPTION, reactivateSubscriptionSaga);
  yield takeEvery(PAY_MEMBERSHIP, payMembershipSaga);
  yield takeEvery(CREATE_SUBSCRIPTION, createSubscriptionSaga);
  yield takeEvery(CREATE_SUBSCRIPTION_NATIVE, createSubscriptionNativeSaga);
}
