import React from 'react';
import { IonCheckbox, IonItem, IonLabel, IonNote } from '@ionic/react';
import Basket from '../../lib/basket';

const { formatPrice } = Basket;

export const SubscriptionLine = (__, clickHandler, key, isChecked, name, description, price) => (
  <IonItem className="box-wrapper" lines="none" key={key}>
    <IonCheckbox color="secondary" slot="start" checked={isChecked} onClick={clickHandler} />
    <IonLabel>
      <IonNote>{name}</IonNote>
      <IonLabel>{description}</IonLabel>
    </IonLabel>
    <IonNote className="ion-text-right" slot="end">
      <IonLabel>{formatPrice(price)}</IonLabel>
      {__('per month')}
    </IonNote>
  </IonItem>
);
