import React from 'react';
import './index.css';

const HistoryOrderBar = ({ label, onDismiss, handleClick }) => (
  <>
    <div className="history-order-bar-scrim" onClick={onDismiss ? onDismiss : null}></div>
    <div className="history-order-bar" onClick={handleClick}>
      <div className="history-order-bar-body ellipsis">{label}</div>
    </div>
  </>
);

export default HistoryOrderBar;
