import React from 'react';
import { IonItem, IonButton, IonGrid, IonRow, IonCol, IonAlert } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { Title, SmallText, Spacer, StrongText, NormalText } from '../../components/common';
import Box, { BoxHeader } from '../../components/box';
import { connect } from 'react-redux';
import { createNewBasketInstance } from '../../lib/basket';
import { forwardTo /*, checkCancel*/ } from '../../lib/utils';
import OrderContent from '../../components/orderContent';
import AmountPaid from '../../components/amountPaid';
import SwipableTabs from '../../components/swipeableTabs';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import { withRouter } from 'react-router';
import { checkCancelOrder, setOrdersProp } from '../../store/orders/actions';
import OrderStatus, { lastEventForPill, updateOrder } from '../../components/orderStatus';
import { PillStatusGroup } from '../../components/pillStatus';
import { UPDATE_STRIPE_ORDER } from '../../store/constants';
import './index.css';

class OrderCompleted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancel: true,
      orderId: null,
      restaurantId: null,
      isPayOnAccount: false,
    };
  }
  async componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const lastOrder = this.props.lastOrder;
    if (searchParams.get('canceled') && lastOrder?.status?.toLowerCase() == 'new') {
      this.props.dispatch({ type: UPDATE_STRIPE_ORDER, orderId: lastOrder.id });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.lastOrder) {
      if (prevProps.lastOrder === null || prevProps.lastOrder.id !== this.props.lastOrder.id) {
        const { lastOrder } = this.props;
        const orderArr = lastOrder;
        if (orderArr.payment_token === 'Pay On Account') {
          this.setState({ isPayOnAccount: true });
        } else {
          const now = moment();
          let cutoffTime = orderArr ? orderArr.cutoff_time : now;
          let cutoffTimeMoment = new moment(cutoffTime, 'YYYY-MM-DD HH:mm:ss');
          const utcOffset = cutoffTimeMoment.utcOffset();
          cutoffTimeMoment = cutoffTimeMoment.add('minutes', utcOffset);
          if (now.isBefore(cutoffTimeMoment)) {
            this.setState({
              isCancel: true,
              orderId: orderArr.id,
              restaurantId: orderArr.restaurant_id,
            });
          }
        }
      }
    }
  }

  drawContentTitle = (__, deliveryOption, id, orderType) => {
    if (orderType === 'Outpost Drop-Off') {
      return (
        <Title>
          {__('Drop Point')} {__('Order')} #{id}
        </Title>
      );
    } else if (orderType === 'Delivery') {
      return (
        <Title>
          <strong>
            {__('Delivery')} {__('Order')} #{id}
          </strong>
        </Title>
      );
    } else {
      return (
        <Title>
          {__(orderType)} #{id}
        </Title>
      );
    }
  };

  drawContent = (__, order, orderType, basketInstance, status) => {
    if (!order?.is_gift) {
      if (orderType === 'Click & Collect') {
        return (
          <>
            <div className="box-wrapper order-content-details">
              <div>
                <StrongText>{__('Collect from')}</StrongText>
                <StrongText className="block">{order.restaurant_name}</StrongText>
                <NormalText className="block">
                  {__('Collect at')}:{' '}
                  {basketInstance.formatOrderTime(true, 'DD MMM YYYY [at] h:mm a')}
                </NormalText>
                <NormalText className="block">
                  {__('Order placed on')}:{' '}
                  {moment(order.created_at).format('DD MMM YYYY [at] h:mm a')}
                </NormalText>
              </div>
            </div>
          </>
        );
      } else if (orderType === 'Delivery') {
        return (
          <>
            <div className="box-wrapper order-content-details">
              <div>
                <StrongText>{__('Delivery from')}</StrongText>
                <StrongText className="block">{order.restaurant_name}</StrongText>
                <NormalText className="block">
                  {__('Order placed on')}:{' '}
                  {moment(order.created_at).format('D MMM YYYY [at] h:mm a')}
                </NormalText>
                <NormalText className="block">
                  {__('Delivery at')}: {basketInstance.formatOrderTime(true)}
                </NormalText>
              </div>
            </div>
          </>
        );
      } else if (orderType === 'Outpost Drop-Off') {
        return (
          <>
            <div className="box-wrapper order-content-details">
              <div>
                <StrongText>{__('Drop-off at')}</StrongText>
                <StrongText className="block">{order.restaurant_name}</StrongText>
                <NormalText className="block">
                  {__('Order placed on')}:{' '}
                  {moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}
                </NormalText>
                <NormalText className="block">
                  {__('Drop-off Time')}: {basketInstance.formatOrderTime(true)}
                </NormalText>
              </div>
            </div>
          </>
        );
      } else if (orderType === 'Table') {
        return (
          <>
            <div className="box-wrapper order-content-details">
              <div>
                <StrongText>{__('Ordered at')}</StrongText>
                <StrongText className="block">{order.restaurant_name}</StrongText>
                <StrongText className="block">
                  {__('Table Number')}-{order.table_name}
                </StrongText>
                <NormalText className="block">
                  {__('Order placed on')}:{' '}
                  {moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}
                </NormalText>
              </div>
            </div>
          </>
        );
      }
    }
    if (orderType === 'scheduled-collection') {
      return (
        <>
          <div className="box-wrapper order-content-details">
            <div>
              <StrongText>{__('Collect from')}</StrongText>
              <StrongText className="block">{order.restaurant_name}</StrongText>
              <NormalText className="block">
                {__('Collect at')}:{' '}
                {basketInstance.formatOrderTime(true, 'DD MMM YYYY [at] h:mm a')}
              </NormalText>
              <NormalText className="block">
                {__('Order placed on')}:{' '}
                {moment(order.created_at).format('DD MMM YYYY [at] h:mm a')}
              </NormalText>
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  drawOrder = (order, orderCompletePage, basketInstance, isPayOnAccount) => {
    const { __, cards } = this.props;
    const orderType = basketInstance.getOrderType();
    //const paymentCard = this.props.cards.find(card => order.payment_token === card.id)
    return (
      <>
        <Box>
          <BoxHeader>
            <IonGrid className="okx-box-header-grid">
              {this.drawContent(
                __,
                order,
                orderType,
                basketInstance,
                orderCompletePage,
                isPayOnAccount,
              )}
            </IonGrid>
          </BoxHeader>
          <br />
          <OrderContent basketInstance={basketInstance} type="orderHistory" />
          {!isPayOnAccount && <AmountPaid order={order} cards={cards} />}
        </Box>
      </>
    );
  };

  cancelOrder = () => {
    const { orderId, isCancel, restaurantId } = this.state;
    const { lastOrder } = this.props;
    let order_id = orderId ? orderId : lastOrder?.id;
    let restaurant_id = restaurantId ? restaurantId : lastOrder?.restaurant_id;
    if (isCancel) {
      this.props.dispatch(checkCancelOrder(order_id, restaurant_id));
      this.setState({ orderId: null, restaurantId: null });
    }
  };

  noOrder = () => (
    <IonItem lines="none">
      <div tabIndex="0" className="sectiontitle" style={{ width: '100vh', textAlign: 'center' }}>
        {this.props.__('No order')}
      </div>
    </IonItem>
  );

  backHandler = () => {
    const { location } = this.props;
    if (location && location.state && location.state.completedOrder) {
      forwardTo('/dashboard');
      forwardTo('/click-and-collect');
      Basket.reset();
    }
  };

  handleCancelOrderModal = (flag) => {
    this.props.dispatch(setOrdersProp('cancelOrderModal', flag));
  };

  handleInfoCancelOrderModal = (flag) => {
    this.props.dispatch(setOrdersProp('infoCancelOrderModal', flag));
  };

  render() {
    const { __, lastOrder, latestOrderEvent, cards } = this.props;
    const { isPayOnAccount } = this.state;
    const order = this.props.order || lastOrder;
    const orderCompletePage = true;
    const basketInstance = createNewBasketInstance();
    basketInstance.recreateOrder(order);

    updateOrder(order, latestOrderEvent);
    const pillItems = lastEventForPill(order, basketInstance.getOrderType(order), __);
    const orderType = basketInstance.getOrderType();
    return (
      <Layout
        hideSecondToolbar
        headerWithTitle
        headerTitle={`${__('Order')} #${order?.id}`}
        scrollY={false}
      >
        <div className="absolute-content history-details scrollable-y">
          {order ? (
            <>
              <Title className="web-only">{`${__('Order')} #${order.id ? order.id : ''}`}</Title>
              <PillStatusGroup className="uppercase" items={pillItems} />
              <Spacer size={1} />

              {this.drawContent(__, order, orderType, basketInstance)}
              <Spacer size={1} />
              <StrongText>{__('Items Ordered')}</StrongText>
              <OrderContent basketInstance={basketInstance} type="orderHistory" />
              <Spacer size={1} />
              <StrongText>{__('Payment')}</StrongText>
              <AmountPaid order={order} cards={cards} />
              <Spacer size={1} />
              <StrongText>{__('Order Status')}</StrongText>

              <OrderStatus order={order} orderType={basketInstance.getOrderType(order)} />
              {order.status.toLowerCase() == 'new' ||
                order.status.toLowerCase() == 'failed' ||
                order.status.toLowerCase() == 'cancelled' ||
                order.status.toLowerCase() == 'order_canceled' ||
                order.status.toLowerCase() == 'payment failed' ? null : (
                <IonButton
                  expand="block"
                  fill="clear"
                  className="link underlined"
                  color="dark"
                  onClick={() => this.handleCancelOrderModal(true)}
                >
                  {__('Cancel this order')}
                </IonButton>
              )}
            </>
          ) : null}

        </div>
        <IonAlert
          isOpen={this.props.cancelOrderModal}
          onDidDismiss={() => this.handleCancelOrderModal(false)}
          header={__('Confirm')}
          message={__('Do you want to cancel this order?')}
          // message={__(
          //   'Sorry, it is not currently possible to cancel this order. Please refer to <a href="/faq" >FAQ</a> for more help.',
          // )}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: __('Remove'),
              handler: () => this.cancelOrder(),
            },
          ]}
        />
        <IonAlert
          cssClass="custom-ion-alert"
          isOpen={this.props.infoCancelOrderModal === true}
          onDidDismiss={() => this.handleInfoCancelOrderModal(false)}
          header={__('Cancel Order')}
          message={__(
            'Sorry, it is not currently possible to cancel this order. Please refer to <a href="/faq" >FAQ</a> for more help.',
          )}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
            },
          ]}
        />
      </Layout>
    );
  }
}

const mapStateToProps = (store) => {
  const orders = store.orders;
  const { cancelOrderModal, latestOrderEvent, infoCancelOrderModal } = orders;
  const { restaurants } = store.restaurants;
  let lastOrder = null;
  const orderHistory = orders.orderHistory;
  if (orderHistory && orderHistory.length > 0) {
    lastOrder = orderHistory[0];
  }

  return {
    lastOrder,
    cards: orders.cards || [],
    restaurants: restaurants || [],
    cancelOrderModal,
    latestOrderEvent,
    infoCancelOrderModal,
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderCompleted)));
