import React from 'react';
import {
  IonButton,
  IonInput,
  IonToggle,
  IonItem,
  IonLabel,
  IonList,
  IonAlert,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { connect } from 'react-redux';
import Layout from '../../components/layout';
import PasswordInput from '../../components/passwordInput';
import { forwardTo, getDefaultRoute } from '../../lib/utils';
import { registerRequest, setModal, setSysLocale, setRegisterForm } from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import {
  FieldError,
  Title,
  SmallText,
  Spacer,
  NormalText,
  StrongText,
} from '../../components/common';
import { validateForm } from '../../lib/utils';
import Loading from '../../components/spinner';
import { getConfig } from '../../appConfig';
import './index.css';
import Mobiscroll from '../../components/mobiscroll';
import { Device } from '@capacitor/device';

const { SelectOption } = Mobiscroll;

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      isOther: false,
      country_code_data: getConfig().country_code_data,
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.navToTerms = this.navToTerms.bind(this);
    this.formConfig = {
      first_name: { type: 'text', required: true },
      last_name: { type: 'text', required: true },
      mobile: { type: 'tel', required: false },
      email: { type: 'email', required: true },
      password: { type: 'password', required: true },
      accepted_terms_and_conditions: { type: 'toggle', toggle: true },
      company_name: { type: 'text', required: true },
      company_address: { type: 'text', required: true },
      company_county: { type: 'text', required: true },
      company_postcode: { type: 'text', required: true },
      company_billing_email: { type: 'email', required: true },
      company_town: { type: 'text', required: true },
      location: { type: 'text', required: true },
      other: { type: 'text', required: true },
    };
  }

  handleInput(key, val) {
    const { dispatch } = this.props;
    dispatch(setRegisterForm(key, val));
    if (key === 'accepted_terms_and_conditions') {
      let formErrors = { ...this.state.formErrors };
      formErrors.accepted_terms_and_conditions = undefined;
      this.setState({ formErrors });
    }
  }

  handleRegister() {
    const { registerFormData } = this.props;
    this.formConfig.company_name.required = registerFormData.is_corporate ? true : false;
    this.formConfig.company_address.required = registerFormData.is_corporate ? true : false;
    this.formConfig.company_county.required = registerFormData.is_corporate ? true : false;
    this.formConfig.company_postcode.required = registerFormData.is_corporate ? true : false;
    this.formConfig.company_billing_email.required = registerFormData.is_corporate ? true : false;
    this.formConfig.company_town.required = registerFormData.is_corporate ? true : false;
    this.formConfig.location.required = registerFormData.is_corporate ? true : false;
    this.formConfig.other.required = registerFormData.location === 'Other' ? true : false;
    let formErrors = validateForm(this.formConfig, registerFormData);

    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      this.props.dispatch(registerRequest());
    }
  }

  componentDidMount() {
    const { loggedIn } = this.props.auth;
    const { registerFormData } = this.props;
    const location = registerFormData.location;
    Device.getLanguageCode().then((res) => {
      const sysLocale = res.value.substr(0, 2);
      if (sysLocale) {
        this.props.dispatch(setSysLocale(sysLocale));
      }
    });
    if (loggedIn) {
      const defaultRoute = getDefaultRoute();
      forwardTo(defaultRoute.path);
    }
    if (location) {
      this.handleSelect(location);
    }
    this.handleInput('is_corporate', true)
  }

  componentDidUpdate(prevProps) {
    if (this.props.appVersion !== prevProps.appVersion)
      this.locationList = getConfig().location ? getConfig().location : [];
  }

  returnToLogin = (history) => history.goBack();

  navToTerms = () => forwardTo('/terms');

  handleSelect = (value) => {
    this.handleInput('location', value);
    value === 'Other' ? this.setState({ isOther: true }) : this.setState({ isOther: false });
  };

  render() {
    const { __, isRegisterModalOpen, registerFormData } = this.props;
    const email = registerFormData.email;
    const password = registerFormData.password;
    const first_name = registerFormData.first_name;
    const last_name = registerFormData.last_name;
    const mobile = registerFormData.mobile;
    const accepted_terms_and_conditions = registerFormData.accepted_terms_and_conditions;
    const is_subscribed = registerFormData.is_subscribed;
    const is_corporate = registerFormData.is_corporate;
    const company_name = registerFormData.company_name;
    const company_town = registerFormData.company_town;
    const company_billing_email = registerFormData.company_billing_email;
    const company_address = registerFormData.company_address;
    const company_county = registerFormData.company_county;
    const company_postcode = registerFormData.company_postcode;
    const location = registerFormData.location;
    const mobile_value = registerFormData.mobile_value;
    const mobile_code = registerFormData.mobile_code;
    const other = registerFormData.other;
    return (
      <Loading>
        <Layout headerTitle="Register" hideSecondToolbar={true} color="transparent">
          <div className="absolute-content scrollable-y">
            <Title>{__('Create Account')}</Title>
            <NormalText>
              {__('To register please enter your email address and a password')}
            </NormalText>
            <Spacer />
            <IonList>
              <IonItem class="input-field-wrapper" lines="none">
                <IonInput
                  placeholder={__('Enter your email address') + ' *'}
                  onIonChange={(e) => this.handleInput('email', e.target.value)}
                  required={true}
                  type="email"
                  pattern="email"
                  inputmode="email"
                  value={email}
                  data-type="email"
                  onIonBlur={(e) => {
                    const usernameInput = document.querySelector(
                      'input[data-type="email"]:-webkit-autofill',
                    );
                    if (usernameInput) {
                      this.handleInput('email', usernameInput.value);
                    }
                    this.handleInput('email', e.target.value);
                  }}
                ></IonInput>
              </IonItem>
              <FieldError className="field-error" value={__(this.state.formErrors.email)} />
              <IonItem class="input-field-wrapper" lines="none">
                <PasswordInput
                  placeholder={__('Enter your password') + ' *'}
                  onIonChange={(e) => this.handleInput('password', e.target.value)}
                  value={password}
                />
              </IonItem>
              <FieldError className="field-error" value={__(this.state.formErrors.password)} />
              <IonItem class="input-field-wrapper" lines="none">
                <IonInput
                  placeholder={__('Enter your first name')}
                  onIonChange={(e) => this.handleInput('first_name', e.target.value)}
                  required={false}
                  type="text"
                  pattern="text"
                  inputmode="text"
                  value={first_name}
                  data-type="first_name"
                  onIonBlur={(e) => {
                    const usernameInput = document.querySelector(
                      'input[data-type="first_name"]:-webkit-autofill',
                    );
                    if (usernameInput) {
                      this.handleInput('first_name', usernameInput.value);
                    }
                    this.handleInput('first_name', e.target.value);
                  }}
                ></IonInput>
              </IonItem>
              <FieldError className="field-error" value={__(this.state.formErrors.first_name)} />
              <IonItem class="input-field-wrapper" lines="none">
                <IonInput
                  placeholder={__('Enter your last name')}
                  onIonChange={(e) => this.handleInput('last_name', e.target.value)}
                  required={false}
                  type="text"
                  pattern="text"
                  inputmode="text"
                  value={last_name}
                  data-type="last_name"
                  onIonBlur={(e) => {
                    const usernameInput = document.querySelector(
                      'input[data-type="last_name"]:-webkit-autofill',
                    );
                    if (usernameInput) {
                      this.handleInput('last_name', usernameInput.value);
                    }
                    this.handleInput('last_name', e.target.value);
                  }}
                ></IonInput>
              </IonItem>
              <FieldError className="field-error" value={__(this.state.formErrors.last_name)} />

              <div className="input-field-container">
                <NormalText>{__('Mobile Number')}</NormalText>
                <div>
                  <IonItem lines="none" className="input-field-wrapper inline-input-field-wrapper">
                    <div>
                      <SelectOption
                        onSet={(e, val) => {
                          this.handleInput('mobile_code', val.getVal());
                          this.handleInput(
                            'mobile',
                            `${val.getVal()}${mobile_value ? mobile_value : ''}`,
                          );
                        }}
                        display="center"
                        value={mobile_code}
                        data={getConfig().country_code_data}
                        onInit={(e, val) => {
                          if (!mobile_code) {
                            this.handleInput('mobile_code', val._tempWheelArray[0]);
                            this.handleInput(
                              'mobile',
                              `${val._tempWheelArray[0]}${mobile_value ? mobile_value : ''}`,
                            );
                          }
                        }}
                      />
                    </div>
                    <IonInput
                      onIonChange={(e) => {
                        this.handleInput('mobile_value', e.target.value);
                        this.handleInput('mobile', `${mobile_code}${e.target.value}`);
                      }}
                      clearInput
                      required={false}
                      type="tel"
                      pattern="tel"
                      inputmode="tel"
                      placeholder="XXXXXXXXXXXX"
                      value={mobile_value}
                    ></IonInput>
                  </IonItem>
                </div>
              </div>

              {this.state.formErrors.mobile ? (
                <FieldError className="field-error" value={__(this.state.formErrors.mobile)} />
              ) : null}

            </IonList>
            <div className="box-wrapper box-list">
              <IonItem lines="none">
                <div>
                  <IonLabel>
                    <StrongText>{__('Accept T&Cs')}</StrongText>
                    <IonLabel className="ion-text-wrap">
                      <SmallText>
                        {__('By signing up you agree to our')}{' '}
                        <span className="secondary-color link underlined" onClick={this.navToTerms}>
                          {__('terms and conditions of service')}
                        </span>{' '}
                        {__('and')}{' '}
                        <span
                          className="secondary-color link underlined"
                          onClick={() => forwardTo('/privacy')}
                        >
                          {__('privacy policy')}
                        </span>
                      </SmallText>
                    </IonLabel>
                  </IonLabel>
                  <FieldError
                    className="field-error"
                    value={__(this.state.formErrors.accepted_terms_and_conditions)}
                  />
                </div>
                <IonToggle
                  style={{ paddingRight: '16px' }}
                  color="primary"
                  slot="end"
                  checked={accepted_terms_and_conditions}
                  onIonChange={(e) =>
                    this.handleInput('accepted_terms_and_conditions', e.detail.checked)
                  }
                />
              </IonItem>
              <IonItem lines="none">
                <div tabIndex="-1"></div>
                <div>
                  <IonLabel>
                    <StrongText>{__('Email')}</StrongText>
                    <IonLabel className="ion-text-wrap">
                      <SmallText>
                        {__(
                          "Untick to opt out of emails containing offers, promos & menu updates",
                        )}
                      </SmallText>
                    </IonLabel>
                  </IonLabel>
                </div>
                <IonToggle
                  style={{ paddingRight: '16px' }}
                  color="primary"
                  slot="end"
                  checked={is_subscribed}
                  onIonChange={(e) => this.handleInput('is_subscribed', e.detail.checked)}
                />
              </IonItem>
              <IonItem lines="none">
                <div tabIndex="-1"></div>
                <div>
                  <IonLabel>
                    <StrongText>{__('Business user')}</StrongText>
                    <IonLabel className="ion-text-wrap">
                      <SmallText>{__('Would you like to register as a business user?')}</SmallText>
                    </IonLabel>
                  </IonLabel>
                </div>
                <IonToggle
                  style={{ paddingRight: '16px' }}
                  color="primary"
                  slot="end"
                  checked={is_corporate}
                  onIonChange={(e) => this.handleInput('is_corporate', e.detail.checked)}
                />
              </IonItem>
            </div>
            <Spacer size={1} />
            {is_corporate && (
              <div>
                <div className="input-field-container">
                  <NormalText>{__('If you are a business user, how did you find out about Pure Catering?')}</NormalText>
                  <IonItem lines="none" className="input-field-wrapper dropdown-field">
                    <IonSelect
                      placeholder="Select"
                      onIonChange={(e) => this.handleSelect(e.detail.value)}
                      value={location}
                      required
                      className="location-select"
                    >
                      {(getConfig().location || []).map((i) => (
                        <IonSelectOption key={i} value={i}>
                          {i}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                </div>

                {this.state.isOther && (
                  <>
                    <IonItem lines="none" class="input-field-wrapper">
                      <IonInput
                        placeholder={__('Type here if other')}
                        onIonChange={(e) => this.handleInput('other', e.target.value)}
                        required={true}
                        value={other}
                        type="text"
                        inputmode="text"
                      />
                    </IonItem>
                    <FieldError className="field-error" value={__(this.state.formErrors.other)} />
                  </>
                )}
                <FieldError className="field-error" value={__(this.state.formErrors.location)} />



                <IonItem class="input-field-wrapper" lines="none">
                  <IonInput
                    placeholder={__('Company Name')}
                    onIonChange={(e) => this.handleInput('company_name', e.target.value)}
                    required={true}
                    type="text"
                    inputmode="text"
                    value={company_name}
                  ></IonInput>
                </IonItem>
                <FieldError
                  className="field-error"
                  value={__(this.state.formErrors.company_name)}
                />
                <IonItem class="input-field-wrapper" lines="none">
                  <IonInput
                    placeholder={__('Company Address')}
                    onIonChange={(e) => this.handleInput('company_address', e.target.value)}
                    required={true}
                    type="text"
                    inputmode="text"
                    value={company_address}
                  ></IonInput>
                </IonItem>
                <FieldError
                  className="field-error"
                  value={__(this.state.formErrors.company_address)}
                />
                <IonItem class="input-field-wrapper" lines="none">
                  <IonInput
                    placeholder={__('Company Town')}
                    onIonChange={(e) => this.handleInput('company_town', e.target.value)}
                    required={true}
                    type="text"
                    inputmode="text"
                    value={company_town}
                  ></IonInput>
                </IonItem>
                <FieldError
                  className="field-error"
                  value={__(this.state.formErrors.company_town)}
                />
                <IonItem class="input-field-wrapper" lines="none">
                  <IonInput
                    placeholder={__('Company County')}
                    onIonChange={(e) => this.handleInput('company_county', e.target.value)}
                    required={true}
                    type="text"
                    inputmode="text"
                    value={company_county}
                  ></IonInput>
                </IonItem>
                <FieldError
                  className="field-error"
                  value={__(this.state.formErrors.company_county)}
                />
                <IonItem class="input-field-wrapper" lines="none">
                  <IonInput
                    placeholder={__('Company Postcode')}
                    onIonChange={(e) => this.handleInput('company_postcode', e.target.value)}
                    required={true}
                    type="text"
                    inputmode="text"
                    value={company_postcode}
                  ></IonInput>
                </IonItem>
                <FieldError
                  className="field-error"
                  value={__(this.state.formErrors.company_postcode)}
                />
                <IonItem class="input-field-wrapper" lines="none">
                  <IonInput
                    placeholder={__('Company Billing Email')}
                    onIonChange={(e) => this.handleInput('company_billing_email', e.target.value)}
                    required={true}
                    type="text"
                    inputmode="text"
                    value={company_billing_email}
                  ></IonInput>
                </IonItem>
                <FieldError
                  className="field-error"
                  value={__(this.state.formErrors.company_billing_email)}
                />
              </div>
            )}
            <Spacer />
            <IonButton
              expand="block"
              color="primary"
              className="register-button"
              onClick={() => this.handleRegister()}
            >
              {__('Register')}
            </IonButton>
          </div>
        </Layout>
        <IonAlert
          isOpen={isRegisterModalOpen}
          onDidDismiss={() => this.props.dispatch(setModal('isRegisterModalOpen', false))}
          header={__('Success')}
          message={__('Register processed. Please check your mail')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.props.dispatch(setModal(('isRegisterModalOpen', false))),
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, isRegisterModalOpen, registerFormData } = state.profile;
  const { appVersion } = state.common;
  return {
    auth,
    isRegisterModalOpen,
    registerFormData,
    appVersion,
  };
};

export default connect(stateToProps)(withTranslation(Register));
