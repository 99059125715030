import React from 'react';
import { connect } from 'react-redux';
import {
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonButton,
} from '@ionic/react';
import Layout from '../../components/layout';
import { NormalText, Spacer, Title } from '../../components/common';
import { withTranslation } from '../../lib/translate';
import {
  setDeliveryOption,
  clearBasketWarning,
  setScrollTop,
  setSelectedSubscription,
  setOrderTypeRegular,
  getSubscriptions,
} from '../../store/actions';
import { forwardTo, getSingleDeliveryOption, isWebConfig } from '../../lib/utils';
import { getConfig } from '../../appConfig';
import api from '../../lib/api';
import './index.css';

const { delivery } = getConfig();

class DeliveryOptions extends React.Component {
  constructor() {
    super();
    this.state = {
      delivery: [],
      option: null,
    };
  }
  setDeliveryOption = () => {
    const delivery = this.state.option;

    this.props.dispatch(setDeliveryOption(delivery));
    this.props.setOrderTypeRegular();
    forwardTo(
      delivery.id === 'delivery'
        ? '/delivery'
        : delivery.id === 'charter-delivery'
        ? '/delivery'
        : delivery.id === 'pick-up-point'
        ? '/pick-up-point'
        : delivery.id === 'table'
        ? '/order-to-table'
        : '/click-and-collect',
    );
  };

  checkForSingleDeliveryOption = () => {
    const singleDeliveryOption = getSingleDeliveryOption();
    if (singleDeliveryOption) {
      this.setDeliveryOption(singleDeliveryOption);
    }
  };

  componentDidMount() {
    const { defaultMenuId } = this.props;
    this.props.dispatch(clearBasketWarning());
    this.props.dispatch(setScrollTop(0));
    // Basket.reset()
    this.checkForSingleDeliveryOption();
    if (defaultMenuId) {
      api.getDefaultMenu(defaultMenuId).then((res) => {
        this.props.dispatch({ type: 'SET_RESTAURANT_PROP', key: 'defaultMenu', value: res });
      });
    }
    this.props.dispatch(getSubscriptions());
  }

  componentDidUpdate() {
    this.checkForSingleDeliveryOption();
  }
  selectOption(option) {
    this.setState({ option: option });
  }
  render() {
    const { __, orderHistory } = this.props;
    const { option } = this.state;

    return (
      <Layout
        headerWithTitle
        headerTitle={__('Start New Order')}
        hideSecondToolbar={true}
        color="transparent"
        noPadding={true}
      >
        <div className="absolute-content">
          <Title className="web-only">{__('Start New Order')}</Title>
          {isWebConfig() && <Spacer size={1} />}
          <IonList lines="none" className="box-wrapper box-list">
            {delivery.filter(el=>el.id!=='reorder').map((d, index) => {
              let label = __(d.label);
              return (
                <IonItem
                  className="pointer"
                  key={'delivery-option-' + index}
                  onClick={() => this.selectOption(d)}
                >
                  <div tabIndex="-1"></div>
                  <IonLabel>{label}</IonLabel>
                  <IonCheckbox
                    checked={option && d.id === option.id}
                    slot="start"
                    color="secondary"
                  />
                </IonItem>
              );
            })}
            {orderHistory.length > 0 && (
              <IonItem onClick={() => forwardTo('/history')}>
                <IonCheckbox slot="start" color="secondary" />
                <IonLabel className="">{__('Order it again')}</IonLabel>
              </IonItem>
            )}
          </IonList>
          <Spacer />
          <IonButton
            disabled={!option}
            expand="block"
            color="primary"
            className="uppercase"
            onClick={() => this.setDeliveryOption()}
          >
            {__('Start New Order')}
          </IonButton>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state) => ({
  deliveryOption: state.orders.deliveryOption,
  defaultMenuId: state.common.defaultMenuId,
  orderHistory: state.orders.orderHistory,
});

const dispatchToProps = { setSelectedSubscription, setOrderTypeRegular };

export default connect(stateToProps, dispatchToProps)(withTranslation(DeliveryOptions));
