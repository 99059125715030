import React, { Component } from 'react';
import BigNumber from '../../lib/bignumber';
import { IonRow, IonCol } from '@ionic/react';
import Basket from '../../lib/basket';
import { NormalText, SmallText } from '../common';
import { withTranslation } from '../../lib/translate';
import { isDefined } from '../../lib/utils';

class AmountPaid extends Component {
  render() {
    const { __, order, cards } = this.props;
    let amount_paid = isDefined(order.amount_paid) ? order.amount_paid : 0;
    if (amount_paid > 0) {
      // amount_paid were represents payed amount in cents
      amount_paid = new BigNumber(amount_paid).div(100).toNumber();
    }

    let label = __('Paid');
    if (order.payment_token && cards && cards.length > 0) {
      const card = cards.find((c) => c.id === order.payment_token);
      if (card) {
        const { brand, last4 } = card;
        label = brand + ' ' + __('card ending') + ' **** ' + last4;
      } else {
        if (order.payment_token === 'apple') {
          label = 'Apple Pay';
        } else if (order.payment_token === 'google') {
          label = 'Google Pay';
        }
      }
    }
    return (
      <div className="box-wrapper">
        <IonRow className="no-border">
          <IonCol size="9" className="paddLR text-start">
            <NormalText className="block">{label}</NormalText>
            <div className="order-paid-status">
              {order.status.toLowerCase() === 'paid' ? __('Completed') : __('Processing')}
            </div>
          </IonCol>
          <IonCol size="3" className="righted paddLR">
            {Basket.formatPrice(amount_paid, true)}
          </IonCol>
        </IonRow>
        {order && order.process_message ? (
          <IonRow className="ion-color-danger">
            <IonCol className="paddLR ion-color-danger">
              <NormalText color="red">*** {__(order.process_message)} ***</NormalText>
            </IonCol>
          </IonRow>
        ) : null}
      </div>
    );
  }
}

export default withTranslation(AmountPaid);
