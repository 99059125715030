import React from 'react';
import { connect } from 'react-redux';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  IonItem,
  IonButton,
} from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import { withRouter } from 'react-router';
import { SmallText } from '../../components/common';
import Basket from '../../lib/basket';
import NoData from '../../components/noData';
import { getConfig } from '../../appConfig';
import PillStatus from '../../components/pillStatus';
import { lastEventForPill } from '../../components/orderStatus';

import { forwardTo } from '../../lib/utils';

import { setOrderTypeRegular, clearBasketWarning, setDeliveryOption } from '../../store/actions';

const { delivery } = getConfig();

const doRefresh = (event, getTransactionHistory) => {
  setTimeout(() => {
    event.detail.complete();
    getTransactionHistory();
  }, 1000);
};

const HistoryTab = withRouter(
  ({ __, transactionHistory, getTransactionHistory, profile, history, restaurants, ...rest }) => {
    const type = rest.type || 'order';

    const handleRowClick = (item) => {
      if (type === 'order') {
        history.push('./history-details', { order: item });
      } else {
        // history.push('./history-details')
      }
    };

    const orderAgain = (past_orders, option) => {
      Basket.reset();
      rest.dispatch(setDeliveryOption(option));
      rest.setOrderTypeRegular();
      const selectedRestaurant = restaurants.find((res) => res.id == past_orders.restaurant_id);
      past_orders.active_menu = selectedRestaurant.active_menu;
      past_orders.unavailable_times = selectedRestaurant.unavailable_times;

      forwardTo(option.route, { selectedRestaurant: past_orders });
    };

    return (
      <>
        <IonRefresher slot="fixed" onIonRefresh={(e) => doRefresh(e, getTransactionHistory)}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <div className={`history-content ${type !== '' ? `${type}-tab` : ''}`}>
          {(transactionHistory || []).length === 0 ? (
            <NoData />
          ) : (
            <IonGrid className="box-wrapper box-list">
              {transactionHistory.map((i, index) => {
                const { stamp_power, transaction_date, label, item, location_name } = i;
                const transDate = Basket.getDate(transaction_date);
                let orderLabel = Basket.getOrderType(i.item);
                let option = (delivery || []).find((d) => d.id === orderLabel);
                let orderId = i && i.item ? ' #' + i.item.id : '';
                let status = item && item.status ? item.status : '';
                let locationName =
                  location_name && location_name !== ''
                    ? location_name
                    : i && i.item
                    ? i.item.restaurant_name
                    : '';
                let pillLabel = status;
                try {
                  const lastEvent = lastEventForPill(item, orderLabel, __);
                  if (lastEvent && lastEvent.length > 0) {
                    pillLabel = lastEvent[0];
                  }
                } catch (e) {
                  pillLabel = status;
                }
                return status === 'CREATED' ||
                  status === 'created' ||
                  status === 'new' ||
                  status === 'NEW' ? null : (
                  <>
                    <IonRow className="history-item-status">
                      <IonCol size="8" onClick={() => handleRowClick(item)}>
                        {type === 'order' && (
                          <div>
                            <PillStatus item={pillLabel} />
                          </div>
                        )}
                        <SmallText className="ellipsis">
                          {transDate.format('ddd DD MMMM YYYY ') +
                            __('at') +
                            transDate.format(' h:mm a')}
                        </SmallText>
                        <h2 className="uppercase">
                          {type === 'order' ? (option ? option.label + orderId : '') : __(label)}
                        </h2>
                        <SmallText>{locationName}</SmallText>
                      </IonCol>
                      {type === 'order' ? (
                        <IonCol size="4" className="centered">
                          <IonButton
                            expand="full"
                            shape="round"
                            onClick={() => orderAgain(item, option)}
                            key={`order-btn-${index}`}
                            color="primary"
                            className="reorder-btn"
                          >
                            {__('Order it again')}
                          </IonButton>
                        </IonCol>
                      ) : (
                        <IonCol size="4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <p
                            className={stamp_power < 0 ? 'redeem history-points' : 'history-points'}
                          >
                            <strong>
                              {stamp_power < 0 ? '' : '+'}
                              {stamp_power}
                              <br />
                              {__('points')}
                            </strong>
                          </p>
                        </IonCol>
                      )}
                    </IonRow>
                  </>
                );
              })}
            </IonGrid>
          )}
        </div>
      </>
    );
  },
);

const stateToProps = (store) => {
  return {
    ikentooMenu: store.restaurants.ikentooMenu || {},
    profile: store.profile.profile,
  };
};

const dispatchToProps = { setOrderTypeRegular };
export default connect(stateToProps, dispatchToProps)(withTranslation(HistoryTab));
