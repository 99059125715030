import React from 'react';
import './index.css';

const PromoGenericBar = ({ type, label, onDismiss, handleClick }) => (
  <>
    <div className="promo-generic-bar-scrim" onClick={onDismiss ? onDismiss : null}></div>
    <div className={'promo-generic-bar ' + (type || 'info')} onClick={handleClick}>
      <span className="promo-generic-bar-type">{type || 'info'}:&nbsp;</span>
      <div className="promo-generic-bar-body ellipsis">{label}</div>
    </div>
  </>
);

export default PromoGenericBar;
