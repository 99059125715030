import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonBadge,
  IonIcon,
} from '@ionic/react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import PromoBar from '../../components/promoBar';
import './index.css';
import {
  isDefined,
  isEmptyObject,
  forwardTo,
  getSingleDeliveryOption,
  isToday,
} from '../../lib/utils';

import { getConfig } from '../../appConfig';
import PromoGenericBar from '../../components/promoGenericBar';
import HistoryOrderBar from '../../components/historyOrderBar';
import { lastEventForPill, updateOrder } from '../../components/orderStatus';
import { NormalText, StrongText, Subtitle, Title } from '../../components/common';

const { hasOrdering, hasCampaignManager } = getConfig().appType;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleDelivery: getSingleDeliveryOption(),
      showAlert: false,
      dashboardWithTiles: true,
    };
  }

  getBanner = (orderHistory, latestOrderEvent, __) => {
    const order = orderHistory[0];
    updateOrder(order, latestOrderEvent);
    const pillItems = lastEventForPill(order, latestOrderEvent.type, __, false);
    return '#' + order.id + ': ' + pillItems.join(' ');
  };

  render() {
    const { __, screenName, vouchers, latestOrderEvent, orderHistory } = this.props;
    let lastEventIsToday = false;
    if (latestOrderEvent && latestOrderEvent.events.length > 0) {
      const latestEta = latestOrderEvent.events[latestOrderEvent.events.length - 1]?.eta?.dropoff;
      lastEventIsToday = isToday(new Date(latestEta));
    }
    const lastOrderBanner = latestOrderEvent
      ? this.getBanner(orderHistory, latestOrderEvent, __)
      : '';
    const { singleDelivery } = this.state;
    return (
      <>
        <Layout
          coloredDashboard={this.state.dashboardWithTiles}
          showHamburgerIcon
          hideSecondToolbar={true}
          color="transparent"
          noPadding={true}
        >
          {!this.state.dashboardWithTiles ? (
            <div className="absolute-content dash-layout">
              <IonCard className="dash-card">
                <IonCardHeader className="ion-text-center">
                  <IonLabel color="dark">{__('Welcome Back')}</IonLabel>
                  <IonCardTitle>{screenName}</IonCardTitle>
                </IonCardHeader>

                <IonCardContent className="dash-menu">
                  <IonList>
                    {hasOrdering ? (
                      singleDelivery ? (
                        <IonItem
                          className="clickable"
                          onClick={() => forwardTo('/click-and-collect')}
                        >
                          <div tabIndex="-1"></div>
                          <IonLabel>{__(singleDelivery.label)}</IonLabel>
                        </IonItem>
                      ) : (
                        <IonItem
                          className="clickable"
                          onClick={() => forwardTo('/delivery-options')}
                        >
                          <div tabIndex="-1"></div>
                          <IonLabel>{__('Start New Order')}</IonLabel>
                        </IonItem>
                      )
                    ) : null}
                    <IonItem className="clickable" onClick={() => forwardTo('/get-membership')}>
                      <div tabIndex="-1"></div>
                      <IonLabel>{__('Membership')}</IonLabel>
                    </IonItem>
                    {hasCampaignManager ? (
                      <IonItem className="clickable" onClick={() => forwardTo('/vouchers')}>
                        <div tabIndex="-1"></div>
                        <IonLabel>
                          {__('Vouchers')}
                          {vouchers && vouchers.length === 0 ? null : (
                            <IonBadge color="secondary" className="small">
                              {vouchers.length}
                            </IonBadge>
                          )}
                          <IonBadge color="secondary" className="small">
                            {}
                          </IonBadge>
                        </IonLabel>
                      </IonItem>
                    ) : null}
                    <IonItem
                      lines="none"
                      className="clickable"
                      onClick={() => forwardTo('/loyalty')}
                    >
                      <div tabIndex="-1"></div>
                      <IonLabel>{__('Loyalty')}</IonLabel>
                    </IonItem>
                  </IonList>
                </IonCardContent>
              </IonCard>
              <div className="info-panel">
                {getConfig().appType.hasCampaignManager ? (
                  <PromoBar type={__('info')} vouchers={vouchers} />
                ) : null}
                {latestOrderEvent && lastEventIsToday && (
                  <PromoGenericBar
                    type={__('ORDER')}
                    label={lastOrderBanner}
                    handleClick={() => forwardTo('./history-details', { order: orderHistory[0] })}
                  />
                )}
                {orderHistory.length > 0 && (
                  <HistoryOrderBar
                    label={__('Order it again')}
                    handleClick={() => forwardTo('./history')}
                  />
                )}
              </div>
            </div>
          ) : (
            <>
              {this.props.loggedIn && (
                <div className="dashboard-bars-wrapper ">
                  {getConfig().appType.hasCampaignManager ? (
                    <PromoBar type={__('info')} vouchers={vouchers} />
                  ) : null}
                  {latestOrderEvent && lastEventIsToday && (
                    <PromoGenericBar
                      type={__('ORDER')}
                      label={lastOrderBanner}
                      handleClick={() => forwardTo('./history-details', { order: orderHistory[0] })}
                    />
                  )}
                  {orderHistory.length > 0 && (
                    <HistoryOrderBar
                      label={__('Order it again')}
                      handleClick={() => forwardTo('./history')}
                    />
                  )}
                </div>
              )}
              <div className="dashboard-content">
                <div
                  className="dashboard-header"
                  // style={{ paddingTop: `${this.calculateHeaderHeight()}px` }}
                >
                  <Title>
                    {__('Welcome')} {screenName}
                  </Title>
                </div>
                <div
                  className="dashboard-cards-wrapper"
                  style={{
                    ...(getConfig().frenchDisclaimer?.dashboardDisclaimerImage
                      ? { paddingBottom: '50px' }
                      : null),
                  }}
                >
                  <div
                    className="dashboard-card order-card"
                    onClick={() => forwardTo('/delivery-options')}
                  >
                    <Subtitle>{__('Start New Order')}</Subtitle>
                  </div>
                  <div
                    className="dashboard-card vouchers-card"
                    onClick={() => forwardTo('/vouchers')}
                  >
                    <Subtitle>{__('Vouchers')}</Subtitle>
                  </div>
                  <div
                    className="dashboard-card membership-card"
                    onClick={() => forwardTo('/get-membership')}
                  >
                    <Subtitle>{__('Membership')}</Subtitle>
                  </div>
                </div>
              </div>
            </>
          )}
        </Layout>
      </>
    );
  }
}

const stateToProps = (state) => {
  const { profile, vouchers, auth } = state.profile;
  const { latestOrderEvent, orderHistory } = state.orders;
  let screenName = '';
  if (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) {
    screenName = profile.first_name;
  }
  return {
    screenName,
    vouchers: vouchers || [],
    latestOrderEvent,
    orderHistory,
    loggedIn: auth.loggedIn,
  };
};

export default connect(stateToProps)(withTranslation(Dashboard));
