import moment from './moment';
import {
  isDefined,
  getLocale,
  forwardTo,
  isEmptyObject,
  checkIdenticalArrays,
  getItemTax,
} from './utils';
import { getConfig } from '../appConfig';
import asyncStorage from './asyncStorage';
import { store } from '../index';
import {
  SET_ORDERS_PROP,
  CREATE_ORDER,
  SET_COMMON_MODAL,
  SET_COMMON_PROP,
  CREATE_STRIPE_ORDER,
  RELEASE_VOUCHER,
} from '../store/constants';
import * as actions from '../store/actions';
import BigNumber from './bignumber';
import { createDiscount, createDiscountPackage } from './subscriptionFactory';
import 'moment-timezone';
import { isTaxActiveForOrderType } from './utils';

const { getIkentooMenu, showToast } = actions;
const zero = 0;
const negativeOne = -1;
const positiveOne = 1;
const emptyStr = '';
const errorMessages = {
  requiredOneItem: 'Must have at least one selected option',
  oneOrMode: 'Must have selected zero or one option',
  basketNotValid:
    'Basket has been cleared because collection time is passed. Please start new order',
  maxItems: 'You have selected maximum number of options',
  minItems: 'You must select required minimum of options. Min:',
};
const pointRatio = 100; //100 points = 1 gbp/eur
const enableCollectionTimeValidation = true;
const { delivery } = getConfig();
const _vouchersType = getConfig().vouchersType;
const vouchersType = isDefined(_vouchersType) ? _vouchersType : 1;
class Basket {
  constructor() {
    this.create();
  }

  create = () => {
    this.items = [];
    this.restaurant = null;
    this.menu = null;
    this.collection_time = null;
    this._collection_time = null;
    this.discount_total = zero;
    this.applied_vouchers = [];
    this.applicable_vouchers = [];
    this.total = zero;
    this.subTotal = zero;
    this.selectedCard = null;
    this.pointsApplied = zero;
    this.pointsAppliedValue = zero;
    this.order_type = '';
    this.mobile = null;
    this.po_number = null;
    this.delivery_option = {};
    this.delivery_address = null;
    this.pick_up_point = null;
    this.delivery_price = 0;
    this.delivery_price_exclude_vat = 0;
    this.min_order = 0;
    this.allergen_data = [];
    this.cutoffTime = null;
    this.table_name = null;
    this.discount = null;
    this.discountPackage = null;
    this.subscription = null; // when new subscription is created
    this.restaurant_id = null;
    this.next_collection_time = [];
    this.asapFlag = false;
    this.delivery_menu = {};
    this.itemsTax = 0;
  };

  reset = () => {
    this.create();
    store.dispatch({ type: 'CLEAR_IKENOO_MENU' });
    this.instanceStateChanged();
  };

  // SETTERS ----------------------------------------------------------------------------------------
  // IMPORTANT: all changes of any instance property must triger SAVING new instance state to the local storage (instanceStateChanged)
  setRestaurant = (restaurant = null) => {
    this.restaurant = restaurant;
    if (this.getRestaurant()) {
      this.restaurant_id = this.restaurant.id;
    }
    this.instanceStateChanged();
  };
  setDeliveryMenu = (delivery_menu) => {
    this.delivery_menu = delivery_menu;
    this.instanceStateChanged();
  };
  getDeliveryMenu = () => {
    return this.delivery_menu;
  };
  setMenu = (menu = null) => {
    this.menu = menu;

    this.instanceStateChanged();
  };

  setMobile = (mobile = null) => {
    this.mobile = mobile;

    this.instanceStateChanged();
  };

  setPoNumber = (po_number = null) => {
    this.po_number = po_number;

    this.instanceStateChanged();
  };

  setSelectedCard = (selectedCard) => {
    this.selectedCard = selectedCard;

    this.instanceStateChanged();
  };

  setCollectionTime = (collection_time = null, nextDay = false) => {
    if (collection_time) {
      this._collection_time = collection_time;
      this.collection_time = nextDay
        ? moment(collection_time).tz('Europe/London').add(1, 'days').unix()
        : moment(collection_time).tz('Europe/London').unix();

      this.instanceStateChanged();
      // only for apps which dont have delivery module (only C&C)
      if (!isDefined(delivery)) {
        this.setOrderType('collection');
      }
    } else {
      this._collection_time = null;
      this.collection_time = null;
      this.instanceStateChanged();
    }
  };

  setNextCollectionTime = (next_collection_time = []) => {
    this.next_collection_time = next_collection_time;
  };

  setAsapFlag = (asapFlag = false) => {
    this.asapFlag = asapFlag;
  };

  setCutoffTime = (cutoffTime = null) => {
    if (cutoffTime) {
      this.cutoffTime = cutoffTime;
      this.instanceStateChanged();
    }
  };

  addVoucher = (voucher, applicableVoucher) => {
    if (applicableVoucher.redemption_id) {
      voucher.redemption_id = applicableVoucher.redemption_id;
    }
    if (voucher) {
      this.applied_vouchers = [];
      this.applicable_vouchers = [];
      this.applied_vouchers.push(voucher);
      this.applicable_vouchers.push(applicableVoucher);
      this.instanceStateChanged();
      // this.clearAllDiscounts()
    }
  };

  isServiceChargeEnabled = () => {
    const hasServiceCharge = getConfig().appType.hasServiceCharge;
    return (
      isDefined(hasServiceCharge) && hasServiceCharge && ['table'].indexOf(this.order_type) !== -1
    );
  };

  // available types: delivery, collect, table, pick-up-point
  setOrderType = (orderType = 'collection') => {
    if (
      ['delivery', 'collection', 'table', 'pick-up-point', 'charter-delivery'].indexOf(
        orderType,
      ) === -1
    ) {
      this.toastMessage('Wrong order type');
    }

    const option = (delivery || []).find((d) => d.id === orderType);
    this.order_type = orderType;
    this.delivery_option = option;

    // add service charge
    if (this.isServiceChargeEnabled()) {
      this.service_percentage = getConfig().general.defaultServiceCharge || 0;
    }

    this.instanceStateChanged();
  };

  setTableNumber = (tableNumber) => {
    this.table_name = tableNumber;
    this.instanceStateChanged();
  };

  setDeliveryAddress = (deliveryAddress) => {
    this.delivery_address = deliveryAddress;
    this.pick_up_point = null;
    this.instanceStateChanged();
  };

  setPickUpPoint = (pickUpPoint) => {
    this.pick_up_point = pickUpPoint;
    this.delivery_address = null;
    this.instanceStateChanged();
  };
  setDeliveryPrice = (deliveryPrice) => {
    const profile = store && store.getState()?.profile && store.getState().profile?.profile;
    const isCorporate = profile.is_corporate;
    const { deliveryFeeVatRate } = getConfig()?.taxes || {};
    if (isCorporate) {
      const deliveryPriceExcludeVat = deliveryPrice / (1 + deliveryFeeVatRate / 100);
      this.delivery_price_exclude_vat = deliveryPriceExcludeVat;
    }
    this.delivery_price = deliveryPrice;
    this.instanceStateChanged();
  };

  setMinOrder = (minOrder) => {
    this.min_order = minOrder;
    this.instanceStateChanged();
  };

  setAllergen = (allergen) => {
    if (allergen.length > 0) {
      this.allergen_data.push(allergen);
      this.instanceStateChanged();
    }
  };

  setSubscription = (subscription) => {
    this.subscription = subscription;
    if (subscription) {
      this.discount = createDiscount(subscription);
      this.discountPackage = createDiscountPackage(subscription);
    } else {
      this.discount = null;
      this.discountPackage = null;
    }
    this.instanceStateChanged();
  };

  getMembershipTotal = () => {
    return this.discountPackage?.price * 100 || 0;
  };

  getMembershipName = () => {
    return this.discountPackage?.title || '';
  };

  setDiscount = (subscription) => {
    if (subscription) {
      this.discount = createDiscount(subscription);
    }
    this.instanceStateChanged();
  };

  setItemsTax = (itemsTax = 0) => {
    this.itemsTax = itemsTax;
  };

  getAllergen = () => this.allergen_data;

  getDeliveryAddress = () => this.delivery_address;

  getPickUpPoint = () => this.pick_up_point;

  getDeliveryPrice = () => this.formatPrice(this.delivery_price);

  _getDeliveryPrice = () => this.delivery_price;

  getDeliveryPriceExcludeVat = () => this.formatPrice(this.delivery_price_exclude_vat);

  _getDeliveryPriceExcludeVat = () => this.delivery_price_exclude_vat;

  getDeliveryOption = () => this.delivery_option;

  getMinOrder = () => this.min_order;

  getTableNumber = () => this.table_name;

  getServicePercentage = () => this.service_percentage;

  _getServicePercentage = () => this.getServicePercentage() + '%';

  _getCollectionTime = () => this._collection_time;

  getNextCollectionTime = () => this.next_collection_time;

  calculateServiceCost = () => {
    if (this.isServiceChargeEnabled()) {
      const _service_charge = new BigNumber(this.service_charge); //amount of service charge
      const _order_value = new BigNumber(this.total).minus(_service_charge); //total without service charge
      const _service_percentage = new BigNumber(this.service_percentage);
      this.service_charge = _order_value.div(100).times(_service_percentage).toNumber();
      return this.service_charge;
    }
    return 0;
  };

  _calculateServiceCost = (inlucdeZero) =>
    this.formatPrice(this.calculateServiceCost(), inlucdeZero);

  getServiceCharge = () => this.service_charge;

  _getServiceCharge = (inlucdeZero) => this.formatPrice(this.getServiceCharge(), inlucdeZero);

  selectedChoicesSkus = (choices) => {
    let skus = [];
    if (choices && choices.length > 0) {
      choices.forEach((choice) => {
        if (choice && choice.length > 0) {
          skus.push(...this.selectedChoicesSkus(choice));
        } else {
          if (choice.sku) {
            skus.push(choice.sku);
          }
          return;
        }
      });
    }

    return skus;
  };

  addToBasket = (item) => {
    if (item) {
      if (this.items.length > 0) {
        let foundItem = this.items.find((fItm) => fItm.item.sku === item.item.sku);
        let foundItemIndex = this.items.findIndex((fItm) => fItm.item.sku === item.item.sku);
        if (foundItem && foundItemIndex !== -1) {
          if (
            foundItem.selectedChoices.length === 0 &&
            item.selectedChoices.length === 0 &&
            foundItem.instructions === item.instructions
          ) {
            this.changeItemQuantity(foundItemIndex, item.quantity, true);
          } else {
            let itmSkus = this.selectedChoicesSkus(foundItem.selectedChoices);
            let itemSkus = this.selectedChoicesSkus(item.selectedChoices);
            if (
              checkIdenticalArrays(itmSkus, itemSkus) &&
              foundItem.instructions === item.instructions
            ) {
              this.changeItemQuantity(foundItemIndex, item.quantity, true);
            } else {
              this.items.push(item);
            }
          }
        } else {
          this.items.push(item);
        }
      } else {
        this.items.push(item);
      }


      this.instanceStateChanged();
      this.clearAllDiscounts();
    }
  };

  removeFromBasket = (itemIndex) => {
    if (isDefined(itemIndex) && isDefined(this.items[itemIndex])) {
      this.items = this.items.filter((item, index) => index !== itemIndex);

      this.instanceStateChanged();
      this.clearAllDiscounts();
    }
  };

  changeItemQuantity = (index, value = 0, addOn = false) => {
    if (isDefined(index) && isDefined(this.items[index])) {
      let item = this.items[index];
      if (addOn) {
        item.quantity += value;
      } else {
        item.quantity = value;
      }
      if (item.quantity <= 0) {
        this.items = this.items.filter((i, basketIndex) => basketIndex !== index);
        let allergenIndex = this.allergen_data.findIndex((data) => data[1].sku === item.item.sku);
        if (allergenIndex > -1) {
          let newArr = [...this.allergen_data];
          newArr.splice(allergenIndex, 1);
          this.allergen_data = newArr;
        }
      }
    }

    this.instanceStateChanged();
    this.clearAllDiscounts();
  };

  changeSelectedCard = (cardToken = null) => {
    if (cardToken) {
      this.selectedCard = cardToken;
      this.instanceStateChanged();
    }
  };

  // e.g. points = 700
  // that is 7eur for the ration 100
  applyPoints = (points = zero, currentAvailableBalace = zero, cb) => {
    // check if applied points don't overcomes users available_balance
    let pointsBalance = currentAvailableBalace - points;
    if (pointsBalance < 0) {
      this.toastMessage('You have no points avaliable');
      return;
    }
    // prevent user to apply greater points amount then order total (e.g. total: 7 gbp, applied_points_value: 8 gbt)
    // this.calculatePointsAppliedPrice(this.pointsApplied) -- reset total to the state without applied points
    const newTotal = new BigNumber(this.total)
      .plus(this.calculatePointsAppliedPrice(this.pointsApplied))
      .minus(this.calculatePointsAppliedPrice(points))
      .toNumber();
    if (newTotal < 0) {
      this.toastMessage('No more points can be applied at this time.');
      return;
    }
    this.pointsApplied = points;
    this.instanceStateChanged();
    if (cb) {
      cb();
    }
  };

  // GETTERS ----------------------------------------------------------------------------------------
  toObject = () => {
    const {
      items,
      restaurant,
      mobile,
      po_number,
      collection_time,
      _collection_time,
      total,
      discount_total,
      applied_vouchers,
      applicable_vouchers,
      selectedCard,
      pointsApplied,
      subTotal,
      order_type,
      delivery_option,
      delivery_address,
      pick_up_point,
      delivery_price,
      delivery_price_exclude_vat,
      menu,
      min_order,
      allergen_data,
      cutoffTime,
      table_name,
      service_percentage,
      restaurant_id,
      next_collection_time,
      asapFlag,
      delivery_menu,
      itemsTax,
    } = this;
    return {
      items,
      restaurant,
      mobile,
      po_number,
      collection_time,
      _collection_time,
      discount_total,
      applied_vouchers,
      applicable_vouchers,
      total,
      selectedCard,
      pointsApplied,
      subTotal,
      order_type,
      delivery_option,
      delivery_address,
      pick_up_point,
      delivery_price,
      delivery_price_exclude_vat,
      menu,
      min_order,
      allergen_data,
      cutoffTime,
      table_name,
      service_percentage,
      restaurant_id,
      next_collection_time,
      asapFlag,
      delivery_menu,
      itemsTax,
    };
  };

  itemsCount = () => (this.items || []).length;

  itemsCountAll = () => this.items.map((item) => item.quantity).reduce((a, b) => a + b, 0);

  getDiscountTotal = () => this.discount_total;

  _getDiscountTotal = () => this.formatPrice(this.getDiscountTotal());

  getItems = () => this.items || [];

  getTotal = () => {
    return this.round(this.total, 2);
    // return this.total;
  };

  _getTotal = (inlucdeZero, excludeTaxes) => {
    if (excludeTaxes) {
      return this.formatPrice(this.getTotal() - (this.itemsTax || 0), inlucdeZero);
    }
    return this.formatPrice(this.getTotal(), inlucdeZero);
  };

  getSubTotal = () => this.subTotal;

  _getSubTotal = () => this.formatPrice(this.getSubTotal());

  getRestauranName = () =>
    this.restaurant && this.restaurant.name ? this.restaurant.name : emptyStr;

  getRestaurant = () => this.restaurant || null;

  getMenu = () => this.menu || null;

  getMobile = () => this.mobile || null;

  getPoNumber = () => this.po_number || null;

  getOrderDate = (format = null) =>
    this.collection_time
      ? moment
          .unix(this.collection_time)
          .tz('Europe/London')
          .format(format || 'dddd Do MMMM')
      : emptyStr;

  getOrderTime = (format = null) =>
    this.collection_time
      ? this.asapFlag && this.getOrderType() === 'Click & Collect'
        ? 'ASAP'
        : moment.unix(this.collection_time).tz('Europe/London').format('H:mm a')
      : emptyStr;

  getSelectedCurrency = () =>
    this.restaurant && this.restaurant.currency
      ? this.restaurant.currency
      : getConfig().general.defaultCurrency;

  getAppliedVocuher = () => this.applied_vouchers || [];

  getApplicableVocuher = () => this.applicable_vouchers || [];

  getCurrency = () => {
    const currency = this.getSelectedCurrency();
    const empty = { '': { label: '', beforeNumber: true, includeSpace: false } }; // When no value}
    const currency_symbols = {
      ...empty,
      USD: { label: '$', beforeNumber: true, includeSpace: false }, // US Dollar
      EUR: { label: '€', beforeNumber: true, includeSpace: false }, // Euro
      GBP: { label: '£', beforeNumber: true, includeSpace: false }, // British Pound Sterling
      CHF: { label: '', beforeNumber: false, includeSpace: false },
    };
    const currency_name = currency?.toUpperCase();
    if (currency_symbols[currency_name] !== undefined) {
      return currency_symbols[currency_name];
    } else {
      return empty;
    }
  };

  getCountry = () =>
    this.restaurant && this.restaurant.country_code
      ? this.restaurant.country_code
      : getConfig().general.default_country_code;

  getAppliedPoints = () => this.pointsApplied;

  getProductName = (item = {}, profile) => {
    let productName = emptyStr;
    const locale = getLocale(profile);
    if (item.productName) {
      productName = item.productName;
      if (item.itemRichData && item.itemRichData.texts) {
        const translation = item.itemRichData.texts.find((i) => i.locale === locale);
        if (translation && translation.friendlyDisplayName !== emptyStr) {
          productName = translation.friendlyDisplayName;
        }
      }
    }
    return productName;
  };

  getProductDescription = (item = {}, profile) => {
    let description = emptyStr;
    const locale = getLocale(profile);
    if (item.itemRichData && item.itemRichData.texts) {
      const translation = item.itemRichData.texts.find((i) => i.locale === locale);
      if (translation && translation.description !== emptyStr) {
        description = translation.description;
      } else if (item.itemRichData.texts.length > 0) {
        description = item.itemRichData.texts[0].description;
      }
    }
    return description;
  };

  getOrderType = (order = null) => {
    if (order) {
      if (order.delivery) {
        return 'delivery';
      }
      if (order.take_away) {
        return 'collection';
      }
      if (order.eat_in) {
        return 'table';
      }
      if (order.pick_up_point) {
        return 'pick-up-point';
      }
      return null;
    } else {
      switch (this.order_type) {
        case 'delivery':
          return 'Delivery';
        case 'collection':
          return 'Click & Collect';
        case 'table':
          return 'Table';
        case 'pick-up-point':
          return 'Outpost Drop-Off';
        case 'charter-delivery':
          return 'charter-delivery';
        default:
          return '';
      }
    }
  };

  getOrderTypeRaw() {
    return this.order_type;
  }

  getItemsTax = () => {
    return this.itemsTax || 0;
  };
  // METHODS ----------------------------------------------------------------------------------------

  // get current state of the instance as JSON object
  export = () => {
    const {
      items,
      restaurant,
      mobile,
      po_number,
      collection_time,
      _collection_time,
      applied_vouchers,
      applicable_vouchers,
      selectedCard,
      pointsApplied,
      order_type,
      delivery_option,
      delivery_address,
      pick_up_point,
      delivery_price,
      delivery_price_exclude_vat,
      menu,
      min_order,
      allergen_data,
      cutoffTime,
      table_name,
      service_percentage,
      restaurant_id,
      next_collection_time,
      asapFlag,
      delivery_menu,
      itemsTax,
    } = this;
    return {
      items,
      restaurant,
      mobile,
      po_number,
      collection_time,
      _collection_time,
      applied_vouchers,
      applicable_vouchers,
      selectedCard,
      pointsApplied,
      order_type,
      delivery_option,
      delivery_address,
      pick_up_point,
      delivery_price,
      delivery_price_exclude_vat,
      menu,
      min_order,
      allergen_data,
      cutoffTime,
      table_name,
      service_percentage,
      restaurant_id,
      next_collection_time,
      asapFlag,
      delivery_menu,
      itemsTax,
    };
  };

  //save instance to the local storage
  //this method should track ALL instance changes (must be called in every SETTER)
  saveInstance = async () => {
    // save to local storage
    await asyncStorage.setItem('basket', JSON.stringify(this.export()));
    this.log('Saved to localStorage');
  };

  import = async (basketObject = null) => {
    //reset current instance to the initial state
    this.create();

    if (!basketObject) {
      let storageBasket = await asyncStorage.getItem('basket');
      if (storageBasket) {
        try {
          if (isDefined(storageBasket)) {
            basketObject = JSON.parse(storageBasket);
          }
        } catch (e) {
          this.log('Error: Parsing basket from storage.');
        }
      }
    }

    //restore all relevent instance properties from provided object
    if (isDefined(basketObject)) {
      Object.keys(basketObject).forEach((key) => {
        this[key] = basketObject[key];
      });

      if (
        basketObject.restaurant &&
        basketObject.restaurant.business_location_id &&
        basketObject.restaurant.menu_id &&
        basketObject.items.length > 0 &&
        !basketObject.menu
      ) {
        const { menu_id, business_location_id } = basketObject.restaurant;
        const _menu_id = menu_id || this.menu;

        store.dispatch(getIkentooMenu(_menu_id, business_location_id, false));
      }

      //recalculate totals and skip saving to the local storage
      this.instanceStateChanged(false);

      this._isCollectionTimeStillValid();
    } else {
      this.log("LocalStorage basket don't exists.");
    }
  };

  // eslint-disable-next-line no-console
  log = (message = null) =>
    console.log('Basket: ', message ? '(' + message + ')' : '', this.toObject());

  calculateTotal = () => {
    const total = new BigNumber(zero);
    // this.total = total.plus(this.calculateSubTotal()).plus(this.calculatePointsAppliedPrice(null, true)).plus(this.delivery_price).plus(this.calculateAppliedVocuhersPrice(true)).toNumber()
    const parsedTotal = total
      .plus(this.calculateSubTotal())
      .plus(this.calculatePointsAppliedPrice(null, true))
      .plus(this.delivery_price)
      // plus(this.calculateAppliedVocuhersPrice(true)).
      .plus(this.calculateServiceCost())
      .minus(this.calculateMembershipDiscount())
      .plus(this.membershipSubscription())
      .toNumber();
    this.total = parsedTotal >= 0 ? parseFloat(parsedTotal.toFixed(2)) : 0;
  };

  calculateTotalWithoutDiscounts = () => {
    const total = new BigNumber(zero);
    const parsedTotal = total.plus(this.calculateSubTotal()).plus(this.delivery_price).toNumber();
    return parsedTotal >= 0 ? parseFloat(parsedTotal.toFixed(2)) : 0;
  };

  calculateSubTotal = () => {
    const profile = store && store.getState()?.profile && store.getState().profile?.profile;
    const isCorporate = profile.is_corporate;
    this.subTotal = new BigNumber(zero);
    let itemsTax = new BigNumber(zero);
    if (isCorporate) {
      itemsTax = itemsTax.plus(this._getDeliveryPrice()).minus(this._getDeliveryPriceExcludeVat());
    }
    this.items.forEach((basketItem) => {
      const { itemPrice, totalItemTax } = this.calculateItemPriceWithTax(basketItem);
      this.subTotal = this.subTotal.plus(itemPrice);
      itemsTax = itemsTax.plus(this.round(totalItemTax));
    });
    this.subTotal = this.subTotal.plus(this.calculateAppliedVocuhersPrice(true)).toNumber();
    this.itemsTax = this.round(itemsTax.toNumber(), 2);
    return this.subTotal;
  };

  calculateMembershipDiscount = () => {
    const subtotal = this.calculateSubTotal();
    if (subtotal === 0 || this.order_type !== 'collection') {
      return 0;
    }
    return this.discount ? this.discount.calculate_discount(subtotal) : 0;
  };

  membershipSubscription = () => (this.discountPackage ? this.discountPackage.price : 0);

  calculateItemPrice = (basketItem, includeSubItems = true) => {
    const { item, quantity, selectedChoices } = basketItem;
    let itemPrice = new BigNumber(zero);
    let menuDealTotal = new BigNumber(zero);
    let selectedChoicesPrice = new BigNumber(zero);
    let subItemPrice = new BigNumber(zero);
    if (item && item.productPrice) {
      itemPrice = parseFloat(item.productPrice);
    }

    if (includeSubItems && selectedChoices && selectedChoices.length > 0) {
      //go throught all groups
      selectedChoices.forEach((menuDealGroup) => {
        if (menuDealGroup && menuDealGroup.length > 0) {
          //go throught all selected choices
          menuDealGroup.forEach((selectedChoice) => {
            selectedChoicesPrice = new BigNumber(parseFloat(selectedChoice.productPrice));
            if (selectedChoice.productPrice && selectedChoice.productPrice !== '') {
              menuDealTotal = menuDealTotal.plus(
                selectedChoicesPrice.times(
                  isDefined(selectedChoice.quantity) ? selectedChoice.quantity : 1,
                ),
              );
            }
            if (selectedChoice.subItems) {
              selectedChoice.subItems.forEach((subItem) => {
                subItemPrice = new BigNumber(parseFloat(subItem.productPrice));
                if (subItem.productPrice && subItem.productPrice !== '') {
                  menuDealTotal = menuDealTotal.plus(
                    subItemPrice.times(isDefined(subItem.quantity) ? subItem.quantity : 1),
                  );
                }
              });
            }
          });
        }
      });
    }
    return new BigNumber(itemPrice).plus(menuDealTotal).times(quantity).toNumber();
  };

  calculateItemPriceWithTax = (basketItem, includeSubItems = true) => {
    const { item, quantity, selectedChoices } = basketItem;
    const orderType = this.getOrderTypeRaw();
    const profile = store && store.getState()?.profile && store.getState().profile?.profile;
    const excludeTaxes = profile.is_corporate ? isTaxActiveForOrderType(orderType) : false;
    let itemPrice = new BigNumber(zero);
    let menuDealTotal = new BigNumber(zero);
    let selectedChoicesPrice = new BigNumber(zero);
    let subItemPrice = new BigNumber(zero);
    let totalItemTax = new BigNumber(zero);
    if (item && item.productPrice) {
      if (excludeTaxes) {
        const itemTax = getItemTax(item, orderType);
        totalItemTax = totalItemTax.plus(itemTax?.itemTax || 0);
      }
      itemPrice = parseFloat(item.productPrice);
    }

    if (includeSubItems && selectedChoices && selectedChoices.length > 0) {
      //go throught all groups
      selectedChoices.forEach((menuDealGroup) => {
        if (menuDealGroup && menuDealGroup.length > 0) {
          //go throught all selected choices
          menuDealGroup.forEach((selectedChoice) => {
            if (excludeTaxes) {
              const itemTax = getItemTax(selectedChoice, orderType);
              totalItemTax = totalItemTax.plus(itemTax.itemTax || 0);
            }
            selectedChoicesPrice = new BigNumber(parseFloat(selectedChoice.productPrice));
            if (selectedChoice.productPrice && selectedChoice.productPrice !== '') {
              menuDealTotal = menuDealTotal.plus(
                selectedChoicesPrice.times(
                  isDefined(selectedChoice.quantity) ? selectedChoice.quantity : 1,
                ),
              );
            }
            if (selectedChoice.subItems) {
              selectedChoice.subItems.forEach((subItem) => {
                if (excludeTaxes) {
                  const itemTax = getItemTax(subItem, orderType);
                  totalItemTax = totalItemTax.plus(itemTax.itemTax || 0);
                }
                subItemPrice = new BigNumber(parseFloat(subItem.productPrice));
                if (subItem.productPrice && subItem.productPrice !== '') {
                  menuDealTotal = menuDealTotal.plus(
                    subItemPrice.times(isDefined(subItem.quantity) ? subItem.quantity : 1),
                  );
                }
              });
            }
          });
        }
      });
    }
    return {
      itemPrice: new BigNumber(itemPrice).plus(menuDealTotal).times(quantity).toNumber(),
      totalItemTax: this.round(totalItemTax.times(quantity).toNumber()),
    };
  };

  _calculateItemPrice = (basketItem, includeSubItems, inlucdeZero) =>
    this.formatPrice(this.calculateItemPrice(basketItem, includeSubItems), inlucdeZero);

  // parse sub item as items and then use existing methods
  calculateSubItemPrice = (subItem, quantity = 1) => {
    const item = {
      quantity,
      item: subItem,
    };
    return this.calculateItemPrice(item);
  };

  _calculateSubItemPrice = (subItem, quantity) =>
    this.formatPrice(this.calculateSubItemPrice(subItem, quantity));

  calculateItemPriceByIndex = (itemIndex, includeSubItems) => {
    if (isDefined(itemIndex) && this.items[itemIndex]) {
      return this.calculateItemPrice(this.items[itemIndex], includeSubItems);
    } else {
      return zero;
    }
  };

  _calculateItemPriceByIndex = (itemIndex, includeSubItems) =>
    this.formatPrice(this.calculateItemPriceByIndex(itemIndex, includeSubItems));

  calculateItemPriceByIndexWithoutTax = (itemIndex, includeSubItems) => {
    if (isDefined(itemIndex) && this.items[itemIndex]) {
      const { itemPrice, totalItemTax } = this.calculateItemPriceWithTax(
        this.items[itemIndex],
        includeSubItems,
      );
      return itemPrice - totalItemTax;
    } else {
      return zero;
    }
  };

  _calculateItemPriceByIndexWithoutTax = (itemIndex, includeSubItems) =>
    this.formatPrice(this.calculateItemPriceByIndexWithoutTax(itemIndex, includeSubItems));

  // use appliablePoints to calculate pointsApplieddPrice without need to change instace and then make calculations
  calculatePointsAppliedPrice = (appliablePoints = null, shouldBeNagative = false) => {
    const points = isDefined(appliablePoints) ? appliablePoints : this.pointsApplied;
    if (points > zero) {
      const pointsRealValue = new BigNumber(points).div(pointRatio); //currency value
      return pointsRealValue.times(shouldBeNagative ? negativeOne : positiveOne).toNumber();
    }
    return zero;
  };

  _calculatePointsAppliedPrice = (appliablePoints, shouldBeNagative, inlucdeZero) =>
    this.formatPrice(
      this.calculatePointsAppliedPrice(appliablePoints, shouldBeNagative),
      inlucdeZero,
    );

  formatPrice = (price, inlucdeZero = false) => {
    if (isDefined(price)) {
      if (typeof price === 'string') {
        price = parseFloat(price);
      }
      if (price !== 0 || inlucdeZero) {
        let retValue = '';
        const currencyObj = this.getCurrency();
        let currencySign = currencyObj.label;
        currencySign = currencyObj.includeSpace
          ? currencyObj.beforeNumber
            ? currencySign + ' '
            : ' ' + currencySign
          : currencySign;
        retValue += price < 0 ? '-' : '';
        // before number
        retValue += currencyObj.beforeNumber ? currencySign : '';
        retValue +=
          typeof price === 'string' ? price : (price < 0 ? price * negativeOne : price).toFixed(2);
        //after number
        retValue += currencyObj.beforeNumber ? '' : currencySign;
        return retValue;
      }
    }
    return emptyStr;
  };

  round(v, d = 2) {
    return parseFloat(Math.round(v.toFixed(d + 1) + 'e' + d) + 'e-' + d);
  }

  isProductEnabled = (item) => {
    let format_date = 'YYYY-MM-DD HH:mm:ss';
    const isValid =
      item &&
      isDefined(item.sku) &&
      this.restaurant &&
      [...(this.restaurant.disabled_skus || []), ...(this.restaurant.snoozed_skus || [])].indexOf(
        item.sku.includes('###') ? item.sku.replace('###', '') : item.sku,
      );

    return isValid !== null && isValid !== -1
      ? this.restaurant &&
        this._getCollectionTime() &&
        this.restaurant.snoozeEnd_time.length - 1 >= isValid
        ? moment(moment(this.restaurant.snoozeEnd_time[isValid]).format(format_date))
            .tz('Europe/London')
            .isBefore(moment(this._collection_time).tz('Europe/London').format(format_date))
          ? true
          : false
        : false
      : true;
  };

  isChoicesGroupValid = (item) => item.items.filter((i) => this.isProductEnabled(i)).length > 0;

  calculateVouchersPrice = (vouchers = [], applicableVouchers = [], shouldBeNagative = false) => {
    let cost = zero;
    vouchers.forEach((applied_voucher) => {
      const voucherWithDiscountInfo = applicableVouchers.find(
        (applicable_vocuher) => applicable_vocuher.id === applied_voucher.id,
      );
      if (voucherWithDiscountInfo) {
        cost = new BigNumber(cost).plus(new BigNumber(voucherWithDiscountInfo.cost)).toNumber();
      }
    });
    return new BigNumber(cost)
      .times(shouldBeNagative ? negativeOne : positiveOne)
      .div(pointRatio)
      .toNumber();
  };

  calculateAppliedVocuhersPrice = (shouldBeNagative = false) => {
    return this.calculateVouchersPrice(
      this.applied_vouchers,
      this.applicable_vouchers,
      shouldBeNagative,
    );
  };

  _calculateAppliedVocuhersPrice = (shouldBeNagative, inlucdeZero) =>
    this.formatPrice(this.calculateAppliedVocuhersPrice(shouldBeNagative), inlucdeZero);

  canVoucherBeApplied = (voucher, applicableVoucher, shouldBeNagative = true) => {
    const vouchersPrice = this.calculateVouchersPrice(
      [voucher],
      [applicableVoucher],
      shouldBeNagative,
    );
    return this.subTotal >= vouchersPrice;
  };

  instanceStateChanged = (saveToStorage = true, skipStoreUpdate = false) => {
    this.calculateTotal();
    this.calculateSubTotal();
    if (saveToStorage) {
      this.saveInstance();
    }
    if (!skipStoreUpdate) {
      store.dispatch({ type: SET_ORDERS_PROP, key: 'basketUpdated', value: Date.now() });
    }
  };

  clearAllDiscounts = (clearPoints = true, clearVouchers = true, showMessage = false) => {
    if (clearPoints) {
      this.pointsApplied = zero;
    }
    if (clearVouchers) {
      if(this.applicable_vouchers.length>0){
        store.dispatch({ type: RELEASE_VOUCHER, data:{discount_id:this.applicable_vouchers[0].discount_id,clearVouchers:true}});
      }
      this.applied_vouchers = [];
      this.applicable_vouchers = [];
      
    }
    if (showMessage) {
      store.dispatch({ type: SET_COMMON_MODAL, modal: 'clearDiscountModal', value: true });
      store.dispatch({ type: SET_COMMON_PROP, key: 'clearDiscountModalText', value: showMessage });
    }

    this.instanceStateChanged();
  };

  validateItem = (basketItem) => {
    const { item, selectedChoices } = basketItem;
    let errors =
      item && item.menuDealGroups ? Array((item.menuDealGroups || []).length).fill(null) : [];
    let errorCount = 0;

    if (item) {
      if (item.menuDealGroups && item.menuDealGroups.length > 0) {
        if (selectedChoices && selectedChoices.length > 0) {
          if (item.menuDealGroups.length === selectedChoices.length) {
            item.menuDealGroups.forEach((menuDealGroup, groupIndex) => {
              const selectedChoiceGroup = selectedChoices[groupIndex];
              const { mustSelectAnItem, multiSelectionPermitted, min, max } = menuDealGroup;
              if (this.isChoicesGroupValid(menuDealGroup)) {
                const selectedChoicesQuantities = selectedChoiceGroup.length;

                if (mustSelectAnItem && selectedChoiceGroup.length === 0) {
                  errors[groupIndex] = errorMessages.requiredOneItem;
                  errorCount += 1;
                }
                if (!multiSelectionPermitted && selectedChoiceGroup.length > 1) {
                  if (!menuDealGroup.bundleModifiers.length) {
                    errors[groupIndex] = errorMessages.oneOrMode;
                    errorCount += 1;
                  }
                }
                if (
                  multiSelectionPermitted &&
                  isDefined(max) &&
                  max > 0 &&
                  selectedChoiceGroup.length > 0 &&
                  selectedChoicesQuantities > max
                ) {
                  errors[groupIndex] = errorMessages.maxItems;
                  errorCount += 1;
                }
                if (
                  multiSelectionPermitted &&
                  isDefined(min) &&
                  min > 0 &&
                  selectedChoiceGroup.length > 0 &&
                  selectedChoicesQuantities < min
                ) {
                  errors[groupIndex] = errorMessages.minItems + min;
                  errorCount += 1;
                }
              }
            });
          }
        }
      }
    }
    return {
      errors,
      errorCount,
    };
  };

  isMinimumOrderTotalSatisfied = (showToast = false) => {
    const minOrder = this.getMinOrder();
    let total = this.getSubTotal() + this.delivery_price;
    if (minOrder > 0 && minOrder > total) {
      if (showToast) {
        this.toastMessage('Minimum order must be ' + this.formatPrice(minOrder), 'warning');
      }
      return false;
    }
    return true;
  };

  createOrder = (paymentType, paymentWebType, cb) => {
    if (!isEmptyObject(this.getDeliveryOption()) && this.getDeliveryOption().id === 'delivery') {
      if (this.isMinimumOrderTotalSatisfied()) {
        store.dispatch({ type: CREATE_ORDER, paymentType, paymentWebType, cb });
      }
    } else {
      store.dispatch({ type: CREATE_ORDER, paymentType, paymentWebType, cb });
    }
  };

  createStripeOrder = (paymentType = null, paymentWebType = null, cb) => {
		if (!isEmptyObject(this.getDeliveryOption()) && this.getDeliveryOption().id === 'delivery') {
			if (this.isMinimumOrderTotalSatisfied()) {
				store.dispatch({ type: CREATE_STRIPE_ORDER, paymentType, paymentWebType, cb });
			}
		} else {
			store.dispatch({ type: CREATE_STRIPE_ORDER, paymentType, paymentWebType, cb });
		}
	};
  parseBasketData = (paymentType = null, paymentWebType) => {
    const {
      items,
      selectedCard,
      restaurant,
      mobile,
      po_number,
      collection_time,
      total,
      pointsApplied,
      order_type,
      delivery_option,
      delivery_address,
      pick_up_point,
      delivery_price,
      delivery_price_exclude_vat,
      min_order,
      allergen_data,
      cutoffTime,
      applicable_vouchers,
      table_name,
      service_percentage,
      service_charge,
      restaurant_id,
      subTotal,
      delivery_menu,
      itemsTax,
    } = this;

    let errors = [];
    if (this.itemsCount() === 0) {
      errors.push('Your basket is empty');
    }
    if (!restaurant) {
      errors.push('Please select restaurant');
    }
    // if (hasContactDetails && !mobile) {
    // 	errors.push('Please select mobile')
    // }
    if (!collection_time) {
      errors.push('Please select collection time');
    }
    if (paymentType || paymentWebType) {
      this.selectedCard =
        paymentType === 'apple'
          ? 'Apple Pay'
          : paymentType === 'google'
          ? 'Google Pay'
          : paymentType === 'collectedPay'
          ? 'Pay on collection'
          : paymentType === 'payOnAccount'
          ? 'Pay On Account'
          : paymentWebType === 'APPLE_PAY'
          ? 'Apple Pay'
          : paymentWebType === 'BROWSER'
          ? 'Google Pay'
          : paymentWebType === 'GOOGLE_PAY'
          ? 'Google Pay'
          : paymentWebType === 'LINK'
          ? 'Google Pay'
          : paymentWebType === 'BROWSER_CARD'
          ? 'Google Pay'
          : null;
    } else {
      this.selectedCard = selectedCard;
    }
    // if (total > 0 && !this.selectedCard) {
    //   errors.push('Please select payment card');
    // }
    // if (!cutoffTime) {
    // 	errors.push('Cutoff time empty')
    // }

    if (!this.isCollectionTimeStillValid()) {
      errors.push(errorMessages.basketNotValid);
      this._isCollectionTimeStillValid();
    }

    if (errors.length > 0) {
      errors.forEach((e) => this.toastMessage(e));
      throw errors;
    }
    let rewardCost = 0;
    if (vouchersType === 2) {
      this.applied_vouchers.forEach((i) => (rewardCost += i.cost));
    }
    const parsedItems = items;

    return {
      applicable_vouchers: applicable_vouchers,
      items: parsedItems,
      payment_token: this.selectedCard,
      pay_on_collection: false,
      discount_applied: pointsApplied + rewardCost,
      business_location_id: restaurant.business_location_id,
      collection_time:
        typeof collection_time === 'number' ? collection_time * 1000 : collection_time,
      mobile: mobile,
      po_number: po_number,
      currency: this.getSelectedCurrency(),
      order_type,
      delivery_option,
      delivery_address,
      pick_up_point,
      delivery_price: this.toCents(delivery_price),
      delivery_price_exclude_vat: this.toCents(delivery_price_exclude_vat),
      // properties for delete (later we will calculate total on BO)
      _total: total,
      total: Math.round(this.toCents(total)), //cents
      _subTotal: subTotal,
      subTotal: Math.round(this.toCents(subTotal)),
      min_order,
      allergen_data,
      cutoffTime: cutoffTime * 1000,
      table_name: table_name || null,
      service_charge_percentage: service_percentage,
      _service_charge_value: service_charge,
      service_charge_value: this.toCents(service_charge), //cents,
      subscription_discount: this.toCents(this.calculateMembershipDiscount()),
      subscription_price: this.toCents(this.membershipSubscription()),
      restaurant_id,
      menu_id: delivery_menu?.menu_id || null,
      total_taxes: new BigNumber(this.round(itemsTax) || 0).times(100).toNumber(),
    };
  };

  toCents = (price) => Math.round(new BigNumber(price).times(100).toNumber());

  // ORDER HISTORY RELATED METHODS ----------------------------------------------------------------------------------------

  recreateOrder = (orderFromHistory) => {
    if (orderFromHistory) {
      const {
        items,
        payment_token,
        mobile,
        po_number,
        collection_time,
        discount_applied,
        discount,
        delivery_price,
        delivery_price_exclude_vat,
        delivery_address,
        applied_vouchers,
        table_name,
        service_charge_percentage,
        service_charge_value,
        subscription_discount,
        restaurant_id,
        delivery_menu,
        total_taxes,
      } = orderFromHistory;
      this.items = items || [];
      this.applied_vouchers = applied_vouchers || [];
      this.applicable_vouchers = applied_vouchers || [];
      this.selectedCard = payment_token || '';
      this.mobile = mobile;
      this.po_number = po_number;
      this.collection_time = collection_time;
      this.pointsApplied = discount_applied || vouchersType !== 2 ? discount : 0;
      this.order_type = this.getOrderType(orderFromHistory);
      this.delivery_price =
        !isDefined(delivery_price) || delivery_price === 0
          ? 0
          : new BigNumber(delivery_price).div(100).toNumber();
      this.delivery_price_exclude_vat =
        !isDefined(delivery_price_exclude_vat) || delivery_price_exclude_vat === 0
          ? 0
          : new BigNumber(delivery_price_exclude_vat).div(100).toNumber();
      this.delivery_address = delivery_address;
      this.instanceStateChanged(false, true);
      // probably will need table_order here as well
      this.table_name = table_name;
      this.service_percentage = service_charge_percentage;
      if (subscription_discount) {
        this.discount = {
          title: 'Pure+more Discount',
          calculate_discount: (x) => subscription_discount / 100,
        };
      }
      this.restaurant_id = restaurant_id;
      this.delivery_menu = delivery_menu || {};
      this.calculateTotal();
      this.orderFromHistory = orderFromHistory;
      this.calculateTotal();
      this.itemsTax = new BigNumber(total_taxes).div(100).toNumber();
    }
  };

  parseBasketForCheckVouchers = () => {
    const business_location_id = this.restaurant ? this.restaurant.business_location_id : null;
    const menu_id = this.restaurant ? this.restaurant.menu_id : null;
    const parsedDiscountData = {
      items: this.items.map((item) => {
        const selectedSubItems = [];
        item.selectedChoices.forEach((selectedChoiceGroup) => {
          selectedChoiceGroup.forEach((item) => selectedSubItems.push(item));
        });
        let parsedItem = {
          qty: item.quantity,
          productPrice: isDefined(item.item.productPrice) ? item.item.productPrice : '0.00',
          name: isDefined(item.item.productName) ? item.item.productName : '',
          sku: isDefined(item.item.sku) ? item.item.sku : '',
          sub_items: selectedSubItems.map((selectedSubItem) => {
            return { productPrice: selectedSubItem.productPrice, sku: selectedSubItem.sku };
          }),
        };
        if (isDefined(item.item.sku)) {
          parsedItem.sku = item.item.sku;
        }

        return parsedItem;
      }),
      total: new BigNumber(this.subTotal)
        .minus(this.calculateAppliedVocuhersPrice(true))
        .toNumber(),
      vouchersType: vouchersType,
      restaurant: {
        business_location_id: business_location_id,
        menu_id: menu_id,
      },
    };

    return parsedDiscountData;
  };

  getDate = (date) => {
    if (date && typeof date === 'string') {
      const utcOffset = moment(date).utcOffset();
      return moment(date).tz('Europe/London').add('minutes', utcOffset);
    } else {
      return moment().tz('Europe/London');
    }
  };

  formatOrderTime = (flag, format = null) => {
    let time = null;
    if (flag) {
      time = this.collection_time
        ? this.getDate(this.collection_time).format(format ? format : 'ddd DD MMMM YYYY [at] LT')
        : '';
    } else {
      time = this.collection_time
        ? this.getDate(this.collection_time).format(format ? format : 'ddd DD MMMM YYYY [at] LT')
        : '';
    }
    if (time.indexOf('pm') !== -1) {
      time = time.replace(/ pm/g, '\u00A0pm');
    } else if (time.indexOf('PM') !== -1) {
      time = time.replace(/ PM/g, '\u00A0PM');
    }
    if (time.indexOf('am') !== -1) {
      time = time.replace(/ am/g, '\u00A0am');
    } else if (time.indexOf('AM') !== -1) {
      time = time.replace(/ AM/g, '\u00A0AM');
    }
    return time;
  };

  getItemsForApplePay = (profile, clientName) => {
    let productName = clientName;
    const options = [{ label: productName, amount: this.total }];
    return options;
  };

  getItemsForWebPay = (clientName) => {
    let productName = clientName;
    return {
      label: productName,
      amount: Math.ceil(new BigNumber(this.total).times(100).toNumber()),
    };
  };

  formatPaymentMethod = (cards = [], __, orderCompletePage) => {
    let paymentMethod = '';
    const paymentType = getConfig().payment;
    if (paymentType === 'judopay') {
      const usedCard = cards.find((card) => card.cardToken === this.selectedCard);
      if (usedCard) {
        const { cardType, cardLastFour } = usedCard;
        paymentMethod = orderCompletePage
          ? cardType + ' **** ' + cardLastFour
          : __('Payment card') + ' ' + cardType + ' **** ' + cardLastFour;
      }
      return paymentMethod;
    } else {
      const usedCard = cards.find((card) => card.id === this.selectedCard);
      if (usedCard) {
        const { brand, last4 } = usedCard;
        paymentMethod = orderCompletePage
          ? brand + ' **** ' + last4
          : __('Payment card') + ' ' + brand + ' **** ' + last4;
      } else {
        if (
          this.orderFromHistory &&
          ['Apple Pay', 'apple'].indexOf(this.orderFromHistory.payment_token) !== -1
        ) {
          paymentMethod = 'Apple Pay';
        } else if (
          this.orderFromHistory &&
          ['Google Pay', 'google'].indexOf(this.orderFromHistory.payment_token) !== -1
        ) {
          paymentMethod = 'Google Pay';
        }
      }
      return paymentMethod;
    }
  };

  toastMessage = (message = '', type = 'warning') => store.dispatch(showToast(message, type));

  _isCollectionTimeStillValid = (applyActions = true) => {
    if (enableCollectionTimeValidation && this.collection_time && !this.table_name) {
      const collection_time = this.collection_time * 1000;
      const currentTime = Date.now();
      // const currentTime = 1587742235001
      if (collection_time < currentTime) {
        if (applyActions) {
          this.reset();
          forwardTo('/click-and-collect');
          this.log(errorMessages.basketNotValid);
        }
        return false;
      }
    }
    return true;
  };

  isCollectionTimeStillValid = () => this._isCollectionTimeStillValid(false);

  flattenMenuItems = (menu) => {
    let flatMenu = [];

    menu.forEach((item) => {
      if (item.menuEntry && item.menuEntry.length > 0) {
        flatMenu.push(...this.flattenMenuItems(item.menuEntry));
      } else {
        if (item.sku) {
          flatMenu.push(item);
        }
        return;
      }
    });

    return flatMenu;
  };

  calculateSubTotalWithoutTax = () => {
    let subTotal = new BigNumber(zero);
    this.items.forEach((basketItem) => {
      const { itemPrice, totalItemTax } = this.calculateItemPriceWithTax(basketItem);
      subTotal = subTotal.plus(itemPrice - totalItemTax);
    });
    subTotal = subTotal.plus(this.calculateAppliedVocuhersPrice(true));
    return this.round(subTotal);
  };

  calculateTotalWithoutTax = () => {
    const total = new BigNumber(zero);
    const parsedTotal = total
      .plus(this.calculateSubTotal())
      .plus(this.calculatePointsAppliedPrice(null, true))
      .plus(this.delivery_price)
      // plus(this.calculateAppliedVocuhersPrice(true)).
      .plus(this.calculateServiceCost())
      .minus(this.calculateMembershipDiscount())
      .plus(this.membershipSubscription())
      .toNumber();
    this.total = parsedTotal >= 0 ? parseFloat(parsedTotal.toFixed(2)) : 0;
  };

  getSubTotalWithoutTax = () => this.calculateSubTotalWithoutTax();

  _getSubTotalWithoutTax = () => this.formatPrice(this.getSubTotalWithoutTax());
}

export const createNewBasketInstance = () => new Basket();

export default new Basket();
