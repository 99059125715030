import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { Spacer, Title } from '../../components/common';
import { IonButton, IonImg, IonList } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { getSubscriptions } from '../../store/subscription/actions';
import { setSelectedSubscription } from '../../store/orders/actions';
import { forwardTo, isWebConfig } from '../../lib/utils';
import { SubscriptionLine } from '../../components/subscription-item';

const noImage = () => <div className="membership-image"></div>;
const handleMembership = () => forwardTo('/membership-terms');

const ChooseSubscription = ({
  __,
  subscriptions,
  selectedSubscription,
  getSubscriptions,
  setSelectedSubscription,
}) => {
  useEffect(() => {
    getSubscriptions();
  }, []);

  let imageUrl = null;
  const items = subscriptions.map((e, idx) => {
    const isChecked = idx === selectedSubscription?.idx;
    if (imageUrl === null) {
      imageUrl = e.image;
    }
    const price = e.monthly_cost_inc_vat / 100;

    return SubscriptionLine(
      __,
      () => setSelectedSubscription({ id: e.id, idx }),
      idx,
      isChecked,
      e.name,
      e.short_description,
      price,
    );
  });

  return (
    <Loading>
      <Layout
        showHamburgerIcon
        hideSecondToolbar
        headerWithTitle
        headerTitle={__('Pure +more Membership')}
      >
        <div className="absolute-content flex-row-wrapper">
          <div className="scrollable-y">
            <Title className="web-only">{__('Pure +more Membership')}</Title>
            {isWebConfig() && <Spacer size={2} />}
            {imageUrl && imageUrl !== '' ? (
              <IonImg className="membership-image" src={imageUrl} alt="" />
            ) : (
              noImage()
            )}
            <IonList class="box-wrapper box-list">{items}</IonList>
          </div>
          <div className="flex-min">
            <IonButton
              disabled={!selectedSubscription || selectedSubscription?.idx < 0}
              onClick={handleMembership}
              expand="block"
              className={'checkout-btn '}
            >
              {__('Continue')}
            </IonButton>
          </div>
        </div>
      </Layout>
    </Loading>
  );
};

const mapStateToProps = (store) => ({
  subscriptions: store.subscription.subscriptions,
  selectedSubscription: store.orders.selectedSubscription,
});

const mapDispatchToProps = {
  setSelectedSubscription,
  getSubscriptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(ChooseSubscription));
