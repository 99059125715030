import React from 'react';
import { IonCard, IonCardContent, IonButton, IonInput, IonItem } from '@ionic/react';

import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { Title, Spacer, FieldError, NormalText } from '../../components/common';
import { isEmptyObject, isWebConfig, validateForm } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { sendRefer, showToast } from '../../store/actions';
import './index.css';
import { connect } from 'react-redux';

class ReferAFriend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      formErrors: {},
    };
    this.formConfig = {
      email: { type: 'email', required: true },
    };
  }

  handleInput(key, val) {
    this.setState({ [key]: val });
  }

  handleRefer = () => {
    const { dispatch, auth, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (isEmptyObject(auth)) {
      dispatch(showToast(__('Please login to Refer A Friend'), 'warning'));
    } else if (Object.keys(formErrors).length === 0) {
      const { email } = this.state;
      const data = { email: email };
      this.setState({ email: '' });
      dispatch(sendRefer(data));
    }
  };

  render() {
    const { __ } = this.props;
    const { email } = this.state;
    return (
      <Loading>
        <Layout
          showHamburgerIcon
          hideSecondToolbar
          headerWithTitle
          color="transparent"
          headerTitle={__('Refer A Friend')}
        >
          <div className="absolute-content refer-a-friend" />
          <Title className="web-only">{__('Refer A Friend')}</Title>
          {isWebConfig() && <Spacer size={1} />}

          <IonItem lines="none" className="input-field-wrapper">
            <IonInput
              onIonChange={(e) => this.handleInput('email', e.target.value)}
              required
              placeholder={__("Friend's email address")}
              type="email"
              pattern="email"
              inputmode="email"
              value={email}
            />
          </IonItem>
          <FieldError className="field-error" value={__(this.state.formErrors.email)} />
          <Spacer size="1" />
          <IonButton
            expand="block"
            color="primary"
            strong={true}
            onClick={() => this.handleRefer()}
          >
            {__('Send Invitation')}
          </IonButton>
          <Spacer />
          <NormalText>
            {__(
              'Once your friend has signed up and used the app you will both receive a reward of 10 points!',
            )}
          </NormalText>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth } = state.profile;
  return {
    auth,
  };
};

export default connect(stateToProps)(withTranslation(ReferAFriend));
